import React from "react";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links, adminLinks } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { FiShoppingBag } from "react-icons/fi";
import { useAuthContext } from "../contexts/AuthContext";
import logoBlack from "../data/logo-black.png";
import logoWhite from "../data/logo-white.png";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor, currentMode } =
    useStateContext();
  const { isAdmin, isFrontDesk } = useAuthContext();

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };
  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white-shade text-black-shade"
            >
              <img
                src={currentMode === "Dark" ? logoWhite : logoBlack}
                className="h-8 w-10"
              />{" "}
              <span>Longevity Lounge</span>
            </Link>
            <button
              type="button"
              onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
              className="text-xl rounded-full p-2 text-black-shade dark:text-white-shade dark:hover:bg-white-shade dark:hover:text-black-shade hover:text-white-shade hover:bg-black-shade mt-4 block md:hidden"
            >
              <MdOutlineCancel />
            </button>
          </div>
          <div className="mt-10">
            {
              isAdmin &&
                adminLinks.map((item) => (
                  <div key={item.title}>
                    <p className="text-gray-800 dark:text-gray-400 m-3 mt-4 uppercase">
                      {item.title}
                    </p>
                    {item.links.map((link) => (
                      <NavLink
                        to={`/${link.link}`}
                        key={link.name}
                        onClick={handleCloseSideBar}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : "",
                        })}
                      >
                        {link.icon}
                        <span className="capitalize">{link.name}</span>
                      </NavLink>
                    ))}
                  </div>
                ))
              // <div>
              //   <p className="text-gray-800 dark:text-gray-400 m-3 mt-4 uppercase">
              //     Dashboard
              //   </p>
              //   <NavLink
              //     to={`/admin/ecommerce`}
              //     onClick={handleCloseSideBar}
              //     className={({ isActive }) =>
              //       isActive ? activeLink : normalLink
              //     }
              //     style={({ isActive }) => ({
              //       backgroundColor: isActive ? currentColor : "",
              //     })}
              //   >
              //     <FiShoppingBag />
              //     <span className="capitalize">ecommerce</span>
              //   </NavLink>
              // </div>
            }
            {links.map((item) => (
              <div key={item.title}>
                <p className="text-gray-800 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.link}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                  >
                    {link.icon}
                    <span className="capitalize">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
