import React, { useEffect, useState } from "react";
import { useAsyncError, useNavigate, useParams } from "react-router-dom";
import { useFetchContext } from "../contexts/FetchContext";
import { useStateContext } from "../contexts/ContextProvider";
import Loading from "../components/Loading";
import { usePostContext } from "../contexts/PostContext";
import CustomModal from "../components/CustomModal";

const ProductDetail = () => {
  const { currentMode, currentColor } = useStateContext();
  const { fetchSingleProduct } = useFetchContext();
  const {
    DeleteProductData,
    UpdateProductandServiceStatuses,
    UpdateSingleProductDiscount,
    RemoveSingleProductDiscount,
  } = usePostContext();
  const [productDetails, setProductDetails] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editServiceOpen, setEditServiceOpen] = useState(false);
  const [isAvailableToggle, setIsAvailableToggle] = useState(true);
  const [updatedServices, setUpdatedServices] = useState(null);
  const [discount, setDiscount] = useState("");
  const [discountBtnLoading, setDiscountBtnLoading] = useState(false);
  const [discountRemoveBtnLoading, setDiscountRemoveBtnLoading] =
    useState(false);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchProduct = async () => {
    setProductDetails(null);
    let { data, success } = await fetchSingleProduct(id);
    if (success) {
      setProductDetails(data);
      setIsAvailableToggle(data.isAvailable);
      setUpdatedServices(data.services);
      setDiscount(data.discount);
    }
  };
  useEffect(() => {
    fetchProduct();
  }, []);

  const handleEditServiceOpen = () => setEditServiceOpen(true);
  const handleEditServiceClose = () => {
    setEditServiceOpen(false);
  };
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const deleteProduct = async () => {
    setDeleteLoading(true);
    handleClose();
    await DeleteProductData(id);
    setDeleteLoading(false);
    navigate("/admin/products");
  };
  const editProduct = async () => {
    setEditLoading(true);
    let data = {
      ...productDetails,
      isAvailable: isAvailableToggle,
      services: updatedServices,
    };
    handleEditClose();
    handleEditServiceClose();
    await UpdateProductandServiceStatuses(data, id);
    setEditLoading(false);
    navigate("/admin/products");
  };

  const onUpdateService = (updatedService) => {
    const updatedServicesCopy = [...updatedServices];
    const index = updatedServicesCopy.findIndex(
      (service) => service._id === updatedService._id
    );
    updatedServicesCopy[index] = updatedService;
    setUpdatedServices(updatedServicesCopy);
  };

  const handleDiscount = async () => {
    setDiscountBtnLoading(true);
    await UpdateSingleProductDiscount({ discount: discount }, id);
    setDiscountBtnLoading(false);
    fetchProduct();
  };
  const handleRemoveDiscount = async () => {
    setDiscountRemoveBtnLoading(true);
    await RemoveSingleProductDiscount(id);
    setDiscountRemoveBtnLoading(false);
    fetchProduct();
  };

  return (
    <section className="text-black-shade dark:text-white-shade body-font">
      <div className="container px-5 py-16 mx-auto">
        <div className="flex  w-full mb-10">
          <h1 className="text-4xl font-medium title-font mb-4 tracking-widest">
            Product Detail
          </h1>
        </div>
        {productDetails === null ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading
              color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
              bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
            />
          </div>
        ) : (
          <>
            <div className="lg:w-4/6 mx-auto">
              <div className="rounded-lg h-96 overflow-hidden">
                <div className="w-full h-full bg-black-shade dark:bg-secondary-dark-bg rounded-md">
                  <img
                    alt="content"
                    className="object-contain h-full w-full"
                    src={productDetails.images[0].url}
                  />
                </div>
              </div>
            </div>
            <div className="lg:w-4/6 mt-2 mx-auto">
              <div className="rounded-lg grid grid-cols-3 gap-3 h-60 overflow-hidden">
                {productDetails.images.slice(1).map((image) => (
                  <div
                    key={image._id}
                    className="w-full min-h-full bg-black-shade dark:bg-secondary-dark-bg rounded-md"
                  >
                    <img
                      alt="content"
                      className="object-contain h-full w-full"
                      src={image.url}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:w-4/6 my-4 mx-auto">
              <h1 className="text-3xl">{productDetails.name}</h1>
              <h1 className=" mt-4 text-xl">{productDetails.description}</h1>
            </div>
            <div className="lg:w-4/6 mt-4 mx-auto">
              <h1 className="text-2xl">Services</h1>
            </div>
            <div className="flex flex-col mt-2 divide-y-2 divide-black-shade dark:divide-white-shade lg:w-4/6 sm:mx-auto px-1 sm:mb-2 -mx-2">
              {productDetails.services.map((service) => (
                <div key={service._id} className="p-2 w-full">
                  <div className="rounded flex justify-between w-full h-full items-center">
                    <span className="title-font font-medium ">
                      {service.name}
                    </span>
                    <span className="title-font font-medium ">
                      {service.price}$
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="lg:w-4/6 mt-4 mx-auto">
              <h1 className="text-2xl">Assigned Rooms</h1>
            </div>
            <div className="flex flex-col mt-2 divide-y-2 divide-black-shade dark:divide-white-shade lg:w-4/6 sm:mx-auto px-1 sm:mb-2 -mx-2">
              {productDetails.room.length > 0 ? (
                productDetails?.room?.map((room, i) => (
                  <div key={room._id} className="p-2 w-full">
                    <div className="rounded flex justify-between w-full h-full items-center">
                      <span className="title-font font-medium ">
                        {i + 1} : {room.name}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-2 w-full">
                  <div className="rounded flex justify-between w-full h-full items-center">
                    <span className="title-font font-medium ">
                      No Assigned Rooms
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="lg:w-4/6 mt-4 mx-auto">
              <h1 className="text-2xl">Product Discount</h1>
            </div>
            <div className="lg:w-4/6 mt-4 mx-auto">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="number"
                  name="discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="discount"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Discount
                </label>
              </div>
              <div className="ml-auto flex justify-between items-center">
                <button
                  type="button"
                  onClick={handleRemoveDiscount}
                  style={{ backgroundColor: currentColor }}
                  disabled={discountRemoveBtnLoading ? true : false}
                  className="text-black-shade dark:text-white disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  {discountRemoveBtnLoading ? "Removing..." : "Remove"}
                </button>
                <button
                  type="button"
                  onClick={handleDiscount}
                  style={{ backgroundColor: currentColor }}
                  disabled={discountBtnLoading ? true : false}
                  className="text-black-shade dark:text-white disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  {discountBtnLoading ? "Updating..." : "Submit"}
                </button>
              </div>
            </div>
            <div className="lg:w-4/6 mt-4 mx-auto">
              <h1 className="text-2xl">Availability</h1>
            </div>
            <div className="lg:w-4/6 flex w-full items-center justify-between mt-4 mx-auto">
              <div className="">Product Availability</div>

              <button
                onClick={handleEditOpen}
                disabled={editLoading}
                style={{ backgroundColor: currentColor }}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                {editLoading ? "Changing..." : "Change"}
              </button>
            </div>
            <div className="lg:w-4/6 flex w-full items-center justify-between mt-4 mx-auto">
              <div className="">Services Availability</div>
              <button
                onClick={handleEditServiceOpen}
                disabled={editLoading}
                style={{ backgroundColor: currentColor }}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                {editLoading ? "Changing..." : "Change"}
              </button>
            </div>
            <div className="lg:w-4/6 mt-4 mx-auto">
              <h1 className="text-2xl">Deletion</h1>
            </div>
            <div className="lg:w-4/6 flex w-full items-center justify-between mt-4 mx-auto">
              <div className="">Delete This Product ?</div>
              <button
                onClick={handleOpen}
                disabled={deleteLoading}
                style={{ backgroundColor: currentColor }}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                {deleteLoading ? "Deleting..." : "Delete"}
              </button>
            </div>
            <CustomModal open={editOpen} handleClose={handleEditClose}>
              <div className="w-full flex flex-col">
                <div className="flex my-8 items-center justify-between">
                  <div className=" font-semibold">Product Availability</div>
                  <div className="">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        name="isAvailable"
                        type="checkbox"
                        value={isAvailableToggle}
                        className="sr-only peer"
                        checked={isAvailableToggle}
                        onChange={() =>
                          setIsAvailableToggle(!isAvailableToggle)
                        }
                      />
                      <div className="relative w-9 h-5 bg-black-shade peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="ml-auto font-semibold">
                  <button
                    onClick={handleEditClose}
                    style={{ backgroundColor: currentColor }}
                    className=" disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={editProduct}
                    style={{ backgroundColor: currentColor }}
                    className=" disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Change
                  </button>
                </div>
              </div>
            </CustomModal>
            <CustomModal
              open={editServiceOpen}
              handleClose={handleEditServiceClose}
            >
              <div className="w-full flex flex-col">
                <div className="flex my-8 flex-col gap-4 ">
                  <div className=" font-semibold">Services Availability</div>
                  {productDetails.services.map((service) => (
                    <ServiceToggle
                      key={service._id}
                      onUpdateService={onUpdateService}
                      service={service}
                    />
                  ))}
                </div>
                <div className="ml-auto font-semibold">
                  <button
                    onClick={handleEditServiceClose}
                    style={{ backgroundColor: currentColor }}
                    className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={editProduct}
                    style={{ backgroundColor: currentColor }}
                    className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </CustomModal>
            <CustomModal open={open} handleClose={handleClose}>
              <div className="w-full flex flex-col">
                <div className="py-4 font-semibold">
                  Are you sure want to delete this product ?
                </div>
                <div className="ml-auto font-semibold">
                  <button
                    onClick={handleClose}
                    style={{ backgroundColor: currentColor }}
                    className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                  >
                    No
                  </button>
                  <button
                    onClick={deleteProduct}
                    style={{ backgroundColor: currentColor }}
                    className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Yes
                  </button>
                </div>
              </div>
            </CustomModal>
          </>
        )}
      </div>
    </section>
  );
};

export default ProductDetail;

const ServiceToggle = ({ service, onUpdateService }) => {
  const [isAvailableToggle, setIsAvailableToggle] = useState(
    service.isAvailable
  );
  const handleToggleChange = () => {
    const updatedService = { ...service, isAvailable: !isAvailableToggle };
    onUpdateService(updatedService);
    setIsAvailableToggle(!isAvailableToggle);
  };
  return (
    <div className="flex justify-between items-center">
      <span>{service.name}</span>
      <label className="inline-flex items-center cursor-pointer">
        <input
          name="isAvailable"
          type="checkbox"
          value={isAvailableToggle}
          className="sr-only peer"
          checked={isAvailableToggle}
          onChange={handleToggleChange}
        />
        <div className="relative w-9 h-5 bg-black-shade peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
      </label>
    </div>
  );
};
