import { FaMinusCircle } from "react-icons/fa";
const CartItem = ({
  productDetails,
  name,
  serviceId,
  onClick,
  image,
  date,
  startTime,
  endTime,
  price,
}) => {
  return (
    <div className="flex relative h-full max-h-52 overflow-hidden items-center leading-8 border-b-1 border-color border-gray-600 py-4 px-2">
      <button
        onClick={onClick}
        className="absolute cursor-pointer h-5 w-5 rounded-full hover:bg-white-shade flex items-center justify-center hover:text-black-shade duration-300 transition right-3 top-6"
      >
        <FaMinusCircle className="h-4 w-4" />
      </button>
      <div className=" w-1/3">
        <img className=" h-full w-full rounded-lg" src={image} />
      </div>
      <div className="w-full ml-2">
        <p className="font-semibold text-sm md:text-base ">{name}</p>
        <p className="text-white-shade text-sm font-semibold">
          Service :{" "}
          <span className="line-clamp-1 text-gray-400">
            {
              productDetails.services.filter(
                (service) => service._id == serviceId
              )[0].name
            }
          </span>
        </p>
        <p className="text-white-shade text-sm font-semibold">
          Date : <span className="text-gray-400">{date}</span>
        </p>
        <p className="text-white-shade text-sm font-semibold">
          Start Time : <span className="text-gray-400">{startTime}</span>
        </p>
        <p className="text-white-shade text-sm font-semibold">
          End Time : <span className="text-gray-400">{endTime}</span>
        </p>
        <p className="text-white-shade text-sm font-semibold">
          Price :{" "}
          <span className="inline-flex items-start text-gray-400">
            {price}$
            {productDetails.discount > 0 && (
              <>
                <span className="line-clamp-1 ml-1 text-xs line-through text-gray-400">
                  {
                    productDetails.services.filter(
                      (service) => service._id == serviceId
                    )[0].price
                  }
                  $
                </span>
                <span className=" ml-1 text-xs text-gray-400">
                  (Flat {productDetails?.discount}% off)
                </span>
              </>
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CartItem;
