import React from "react";
import { Link } from "react-router-dom";
import Products from "./Components/Products";
import { useFetchContext } from "../../contexts/FetchContext";
import Loading from "../../components/Loading";

const MainShop = () => {
  const { products } = useFetchContext();
  return (
    <section className="text-body-text bg-shop-body-bg body-font">
      <div className="container px-5 py-10 mx-auto">
        <div className="flex ">
          <div className="flex flex-wrap sm:flex-row flex-col py-3 mb-10">
            <h1 className="sm:w-2/5 text-body-heading font-medium title-font text-5xl mb-2 sm:mb-0">
              Products
            </h1>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          {!products ? (
            <div className="min-h-[20rem] w-full items-center justify-center flex">
              <Loading
                variant={"round"}
                color={"#c78c4e"}
                bgColor={"black"}
                width="2rem"
              />
            </div>
          ) : products !== null && products.length < 1 ? (
            <div className="min-h-[20rem] w-full items-center justify-center flex">
              <div className="text-2xl text-black-shade">
                No Products To Show
              </div>
            </div>
          ) : (
            products?.map((ele, i) => (
              <Products
                key={ele._id}
                link={ele._id}
                image={ele.images[0].url}
                name={ele.name}
                description={ele.description}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default MainShop;
