import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../contexts/AuthContext";
import Loading from "../components/Loading";
import { FiEye, FiEyeOff } from "react-icons/fi";
const Register = () => {
  const { register, error, loading, success } = useAuthContext();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (values) => {
    let data = {
      ...values,
      phoneNumber: "+1" + values.phoneNumber,
      email: values.email.toLowerCase(),
    };
    await register(data);
    if (success) {
      navigate(-1);
    }
  };

  //FORM SCHEMA FOR FORMIK
  const UserSignUp = Yup.object({
    name: Yup.string()
      .min(3, "Minimum 3 Characters")
      .required("Username is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    phoneNumber: Yup.string()
      .matches(/^(\d{10})$/, "Phone number is not valid")
      .required("Phone Number is Required"),
    password: Yup.string()
      .min(6, "Minimum 6 Characters")
      .required("Password is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: UserSignUp,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className="h-screen bg-black-shade w-screen flex items-center justify-center">
      <button
        onClick={() => navigate(-1)}
        className="absolute cursor-pointer hover:bg-shop-head hover:text-body-heading duration-300 transition h-12 flex items-center justify-center rounded-full bg-transparent text-shop-head w-12 top-10 left-10"
      >
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-5 h-5 rotate-180"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </button>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-5 lg:px-2">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-full w-auto"
            src="https://longevitylounge.ca/wp-content/uploads/2023/04/final-logo-02-1.png"
            alt="Your Company"
          />
          <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-body-heading">
            Sign up your account
          </h2>
        </div>
        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-3" onSubmit={formik.handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-shop-head"
              >
                Username
              </label>
              <div className="mt-2">
                <input
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="block w-full bg-shop-head px-1 rounded-md border-0 py-1.5 text-body-heading shadow-sm ring-1 ring-inset ring-body-heading placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-white-shade text-xs mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-shop-head"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="block w-full bg-shop-head px-1 rounded-md border-0 py-1.5 text-body-heading shadow-sm ring-1 ring-inset ring-body-heading placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-white-shade text-xs mt-1">
                    {formik.errors.email}
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-shop-head"
              >
                Phone Number
              </label>
              <div className="flex mt-2">
                <span className="inline-flex items-center px-3 text-sm bg-body-heading border-e-0 rounded-s-md border-0 py-1.5 text-shop-head shadow-sm ring-1 ring-inset ring-body-heading placeholder:text-gray-400 sm:text-sm sm:leading-6">
                  <p>+1</p>
                </span>
                <input
                  name="phoneNumber"
                  type="string"
                  autoComplete="tel"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={10}
                  required
                  className="block w-full bg-shop-head px-1 rounded-e-md border-0 py-1.5 text-body-heading shadow-sm ring-1 ring-inset ring-body-heading placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
              </div>
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="text-white-shade text-xs mt-1">
                  {formik.errors.phoneNumber}
                </div>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-shop-head"
              >
                Password
              </label>
              <div className="mt-2">
                <div className="relative">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"} // Show text or password based on state
                    autoComplete="current-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className="block w-full outline-none bg-shop-head px-1 rounded-md border-0 py-1.5 text-body-heading shadow-sm ring-1 ring-inset ring-body-heading placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex cursor-pointer items-center">
                    {showPassword ? (
                      <FiEyeOff
                        className="text-body-heading "
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <FiEye
                        className="text-body-heading "
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="text-white-shade text-xs mt-1">
                    {formik.errors.password}
                  </div>
                )}
              </div>
            </div>
            <div className="!mt-8">
              <button
                type="submit"
                disabled={loading ? true : false}
                className="flex w-full justify-center rounded-md disabled:border-body-heading-hover bg-body-heading px-3 py-1.5 text-sm font-semibold leading-6 text-shop-head shadow-sm hover:bg-body-heading-hover"
              >
                {loading ? (
                  <Loading
                    variant={"round"}
                    color={"black"}
                    bgColor={"#c78c4e"}
                    width={"1rem"}
                  />
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </form>
          <p className="mt-8 text-center text-sm text-shop-head">
            Already Signed In?{" "}
            <Link
              to={"/login"}
              className="font-semibold leading-6 text-body-heading hover:text-body-heading-hover"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
