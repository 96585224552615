import React, { useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { BsHouse } from "react-icons/bs";
import { MdEdit, MdDelete } from "react-icons/md";
import { Modal, Fade, Backdrop } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import { useAdminContext } from "../contexts/AdminContext";
import CustomModal from "./CustomModal";
import moment from "moment";
const EnableSlot = ({
  id,
  date,
  startTime,
  endTime,
  productName,
  roomName,
}) => {
  const { fetchDisableSlots } = useAdminContext();
  const { currentColor, currentMode } = useStateContext();
  const { EnableSlots } = usePostContext();
  const [btnLoading, setBtnLoading] = useState(false);

  const handleEnableSlot = async () => {
    setBtnLoading(true);
    await EnableSlots(id);
    await fetchDisableSlots();
    setBtnLoading(false);
  };

  return (
    <>
      <div className="p-2 flex flex-col cursor-default md:w-1/2 w-full">
        <div className="h-full transition duration-300 flex flex-col w-full items-center justify-between border-black-shade dark:border-white-shade border p-4 rounded-lg">
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col">
              <div className=" flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font font-medium"
                >
                  {productName}
                </span>
              </div>
              <div className=" flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font text-sm font-medium"
                >
                  {roomName}
                </span>
              </div>
              <div className=" flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font text-sm font-medium"
                >
                  {date}
                </span>
              </div>
              <div className=" flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font text-sm font-medium"
                >
                  {startTime}
                </span>
              </div>
              <div className=" flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font text-sm font-medium"
                >
                  {endTime}
                </span>
              </div>
            </div>
            <div className="flex gap-2 flex-col">
              <button
                onClick={handleEnableSlot}
                style={{ backgroundColor: currentColor }}
                disabled={btnLoading}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                {btnLoading ? "Enabling..." : "Enable"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnableSlot;
