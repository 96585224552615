import React, { useState, useEffect } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import Cart from "./Cart";
import { useStateContext } from "../../../contexts/ContextProvider";
import logo from "../../../data/logo.png";
import Loading from "../../../components/Loading";

const Navbar = () => {
  const { isLoggedIn, isAdmin, isFrontDesk } = useAuthContext();
  const { isClicked, handleClick } = useStateContext();
  const location = useLocation();
  const navigate = useNavigate();
  const isProfile = location.pathname.includes("profile");

  // const toggleBodyScroll = () => {
  //   document.body.style.overflow = isClicked.cart ? "hidden" : "auto";
  // };

  // // Call toggleBodyScroll when component mounts or isClicked.cart changes
  // useEffect(() => {
  //   toggleBodyScroll();
  // }, [isClicked.cart]);
  return (
    <header className="text-shop-head bg-shop-head-bg body-font">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row justify-between items-center">
        <Link
          to={"/"}
          className="flex title-font font-medium items-center mb-4 md:mb-0"
        >
          <div className="h-20">
            <img src={logo} className="h-full w-full" />
          </div>
          <p className="ml-3 text-xl">Longevity Lounge</p>
        </Link>
        <div className="flex items-center justify-between">
          {!isAdmin && !isFrontDesk && (
            <button
              onClick={() => {
                handleClick("cart");
              }}
              className="inline-flex items-center bg-btn-color border-0 py-1 px-3 focus:outline-none hover:bg-body-heading-hover rounded text-base mt-4 md:mt-0"
            >
              Cart
              <FiShoppingCart className="ml-1" />
            </button>
          )}
          {!isAdmin && !isFrontDesk && !isProfile && (
            <>
              {isLoggedIn === null ? (
                <div className="inline-flex items-center ml-2 bg-btn-color border-0 py-1 px-3 focus:outline-none hover:bg-body-heading-hover rounded text-base mt-4 md:mt-0">
                  <Loading
                    variant={"round"}
                    color={"black"}
                    bgColor={"#c78c4e"}
                    width={"1.5rem"}
                  />
                </div>
              ) : isLoggedIn ? (
                <Link
                  className="inline-flex cursor-pointer items-center ml-2 bg-btn-color border-0 py-1 px-3 focus:outline-none hover:bg-body-heading-hover rounded text-base mt-4 md:mt-0"
                  to={"/profile"}
                >
                  Profile
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              ) : (
                <Link
                  to={"/login"}
                  className="inline-flex cursor-pointer items-center ml-2 bg-btn-color border-0 py-1 px-3 focus:outline-none hover:bg-body-heading-hover rounded text-base mt-4 md:mt-0"
                >
                  Login
                </Link>
              )}
            </>
          )}
        </div>
        {isClicked.cart && <Cart />}
      </div>
    </header>
  );
};

export default Navbar;
