import React, { useRef, useState } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import logo from "../data/logo.png";
import Loading from "../components/Loading";
import { TbShoppingCartCheck, TbShoppingCartFilled } from "react-icons/tb";
import { IoTicketOutline } from "react-icons/io5";
import { useFetchContext } from "../contexts/FetchContext";
import { MdEdit } from "react-icons/md";
import CustomModal from "../components/CustomModal";
import { usePostContext } from "../contexts/PostContext";
import { useStateContext } from "../contexts/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";

const AdminProfile = () => {
  const { user, logout } = useAuthContext();
  const { AddAvatar, UpdateAvatar, UpdateProfile, UpdatePassword } =
    usePostContext();
  const { currentColor } = useStateContext();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nameLoadingBtn, setNameLoadingBtn] = useState(false);
  const [passwordLoadingBtn, setPasswordLoadingBtn] = useState(false);
  const [data, setData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const handleLogout = async () => {
    await logout();
    navigate("/");
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (e.target.files.length > 0) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setData({ avatar: reader.result.toString() });
      };
      reader.readAsDataURL(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };
  const inputRef = useRef();

  const handleSubmit = (values, submission) => {
    switch (submission) {
      case "name":
        (async () => {
          setNameLoadingBtn(true);
          await UpdateProfile(values);
          formikName.resetForm();
          setNameLoadingBtn(false);
        })();
        break;
      case "password":
        (async () => {
          setPasswordLoadingBtn(true);
          await UpdatePassword(values);
          formikPassword.resetForm();
          setPasswordLoadingBtn(false);
        })();
        break;
      case "image":
        (async () => {
          setIsLoading(true);
          values?.avatar && user?.avatar?.url
            ? await UpdateAvatar(values)
            : await AddAvatar(values);
          setIsLoading(false);
          handleClose();
        })();
        break;
      default:
        return;
    }
  };

  //FORMIK AND YUP INTIALIZATION FOR PASSWORD
  const ChangePassword = Yup.object({
    oldPassword: Yup.string().required("Old Password is Required"),
    newPassword: Yup.string()
      .notOneOf(
        [Yup.ref("oldPassword")],
        "New Password must be different from Old Password"
      )
      .min(6, "Minimum 6 Characters")
      .required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New Password and Confirm Password must be same"
      )
      .required("Confirm Password is Required"),
  });
  const formikPassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ChangePassword,
    onSubmit: (values) => {
      handleSubmit(values, "password");
    },
  });
  //FORMIK AND YUP INTIALIZATION FOR NAME
  const ChangeName = Yup.object({
    name: Yup.string()
      .min(3, "Minimum 3 Characters")
      .test(
        "is-different-email",
        "New name must be different from the original name",
        function (value) {
          return value !== user.name;
        }
      )
      .required("Name is Required"),
  });
  const formikName = useFormik({
    initialValues: {
      name: user?.name,
    },
    validationSchema: ChangeName,
    onSubmit: (values) => {
      handleSubmit(values, "name");
    },
  });
  return (
    <div className="container px-5 py-24 mx-auto">
      {!user ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading />
        </div>
      ) : (
        <>
          <div className="bg-white dark:bg-secondary-dark-bg dark:text-white-shade text-black-shade rounded-xl p-4 shadow-xl">
            <div className="flex flex-col justify-center items-center">
              <div className="relative w-32 h-32 rounded-full  flex items-center justify-center bg-black-shade border dark:border-white-shade border-black-shade mt-2">
                <div className="relative w-32 h-32 rounded-full overflow-hidden flex items-center justify-center bg-black-shade border dark:border-white-shade border-black-shade ">
                  <img
                    src={user?.avatar?.url || logo}
                    className="object-cover h-full w-full"
                  />
                </div>
                <div className="absolute z-10 bottom-0 right-0">
                  <button
                    style={{ backgroundColor: currentColor }}
                    onClick={handleOpen}
                    className="w-8 h-8 text-white-shade rounded-full flex items-center justify-center"
                  >
                    <MdEdit />
                  </button>
                </div>
              </div>
              <p className="font-semibold text-2xl mt-1">{user.name}</p>
              <p className=" text-base ">{user.email}</p>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="relative w-full cursor-pointer text-white-shade bg-cover bg-black-shade dark:bg-main-dark-bg bg-center group rounded-lg overflow-hidden mt-4 shadow-lg ">
                  <form
                    onSubmit={formikName.handleSubmit}
                    className="w-full h-full max-w-2xl p-4"
                  >
                    <div className="text-xl flex w-full items-center mt-2 justify-center">
                      Change Name
                    </div>
                    <div className="relative z-0 w-full my-5 group">
                      <input
                        type="text"
                        name="name"
                        className="block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none text-white-shade border-white-shade  focus:outline-none focus:ring-0 peer"
                        placeholder=""
                        value={formikName.values.name}
                        onChange={formikName.handleChange}
                        onBlur={formikName.handleBlur}
                        required
                      />
                      <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm  text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Enter New Name
                      </label>
                    </div>
                    {formikName.touched.name && formikName.errors.name && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formikName.errors.name}
                      </div>
                    )}
                    <div className="w-full mt-auto flex justify-end mb-3">
                      <button
                        disabled={nameLoadingBtn ? true : false}
                        type="submit"
                        className=" text-black-shade bg-white-shade disabled:bg-gray-500 font-semibold border-0 py-2 px-3 focus:outline-none text-nowrap rounded text-sm"
                      >
                        {nameLoadingBtn ? "Updating" : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="relative w-full cursor-pointer text-white-shade bg-cover bg-black-shade dark:bg-main-dark-bg bg-center group rounded-lg overflow-hidden mt-4 shadow-lg ">
                  <form
                    onSubmit={formikPassword.handleSubmit}
                    className="w-full max-w-2xl p-4"
                  >
                    <div className="text-xl flex w-full items-center mt-2 justify-center ">
                      Change Password
                    </div>
                    <div className="relative z-0 w-full my-5 group">
                      <input
                        type="text"
                        name="oldPassword"
                        className="block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none text-white-shade border-white-shade  focus:outline-none focus:ring-0 peer"
                        placeholder=""
                        value={formikPassword.values.oldPassword}
                        onChange={formikPassword.handleChange}
                        onBlur={formikPassword.handleBlur}
                        required
                      />
                      <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm  text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Enter Old Password
                      </label>
                    </div>
                    {formikPassword.touched.oldPassword &&
                      formikPassword.errors.oldPassword && (
                        <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                          {formikPassword.errors.oldPassword}
                        </div>
                      )}
                    <div className="relative z-0 w-full my-5 group">
                      <input
                        type="text"
                        name="newPassword"
                        className="block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none text-white-shade border-white-shade  focus:outline-none focus:ring-0 peer"
                        placeholder=""
                        value={formikPassword.values.newPassword}
                        onChange={formikPassword.handleChange}
                        onBlur={formikPassword.handleBlur}
                        required
                      />
                      <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm  text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Enter New Password
                      </label>
                    </div>
                    {formikPassword.touched.newPassword &&
                      formikPassword.errors.newPassword && (
                        <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                          {formikPassword.errors.newPassword}
                        </div>
                      )}
                    <div className="relative z-0 w-full my-5 group">
                      <input
                        type="text"
                        name="confirmPassword"
                        className="block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none text-white-shade border-white-shade  focus:outline-none focus:ring-0 peer"
                        placeholder=""
                        value={formikPassword.values.confirmPassword}
                        onChange={formikPassword.handleChange}
                        onBlur={formikPassword.handleBlur}
                        required
                      />
                      <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm  text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Enter Confirm Password
                      </label>
                    </div>
                    {formikPassword.touched.confirmPassword &&
                      formikPassword.errors.confirmPassword && (
                        <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                          {formikPassword.errors.confirmPassword}
                        </div>
                      )}
                    <div className="w-full flex justify-end mb-3">
                      <button
                        disabled={passwordLoadingBtn ? true : false}
                        type="submit"
                        className="text-black-shade bg-white-shade disabled:bg-gray-500 font-semibold border-0 py-2 px-3 focus:outline-none text-nowrap rounded text-sm"
                      >
                        {passwordLoadingBtn ? "Updating" : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="flex flex-col md:flex-row px-8 w-full max-w-4xl py-6 gap-2 md:gap-4">
                <button
                  onClick={handleLogout}
                  className="text-white-shade bg-black-shade hover:bg-white-shade dark:hover:bg-white-shade hover:text-black-shade dark:bg-main-dark-bg  p-2 md:p-4 rounded-lg shadow-xl w-full flex items-center justify-center bg-cover bg-center h-12 md:h-20 cursor-pointer transition duration-300 mt-2"
                >
                  <h3 className="text-center text-sm md:text-2xl">Logout</h3>
                </button>
              </div>
            </div>
          </div>
          <CustomModal open={open} handleClose={handleClose}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(data, "image");
              }}
              className="w-full max-w-2xl p-4"
            >
              <div className="text-xl flex w-full items-center mt-2 justify-center text-black-shade ">
                Change Picture
              </div>
              <div className="w-full items-center justify-center flex">
                <div className=" w-32 h-32 rounded-full flex items-center justify-center bg-black-shade border overflow-hidden border-black-shade mt-2">
                  <img
                    src={imagePreview || logo}
                    className="object-cover h-full w-full"
                  />
                </div>
              </div>
              <div className="w-full flex items-center justify-center my-6">
                <input
                  className="w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 hidden"
                  aria-describedby="file_input_help"
                  ref={inputRef}
                  onChange={handleProfileImageChange}
                  type="file"
                />
                <button
                  type="button"
                  onClick={() => inputRef.current.click()}
                  className="text-white-shade disabled:bg-gray-500 font-semibold bg-black-shade border-0 py-2 px-3 focus:outline-none hover:bg-gray-900 text-nowrap rounded text-sm md:text-base "
                >
                  Upload Image
                </button>
              </div>

              <div className="w-full flex text-sm justify-end mb-3">
                <button
                  type="button"
                  disabled={isLoading ? true : false}
                  onClick={handleClose}
                  className="text-white-shade mr-3 disabled:bg-gray-500 font-semibold bg-black-shade border-0 py-2 px-3 focus:outline-none hover:bg-gray-900 text-nowrap rounded text-sm md:text-base "
                >
                  Cancel
                </button>
                <button
                  disabled={isLoading ? true : false}
                  type="submit"
                  className="text-white-shade disabled:bg-gray-500 font-semibold bg-black-shade border-0 py-2 px-3 focus:outline-none hover:bg-gray-900 text-nowrap rounded text-sm md:text-base "
                >
                  {isLoading ? "Updating..." : "Submit"}
                </button>
              </div>
            </form>
          </CustomModal>
        </>
      )}
    </div>
  );
};

export default AdminProfile;
