import React from "react";
import { IoIosMore } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {
  FiShoppingBag,
  FiBarChart,
  FiCreditCard,
  FiStar,
  FiShoppingCart,
} from "react-icons/fi";
import {
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsChatLeft,
} from "react-icons/bs";
import { RiContactsLine } from "react-icons/ri";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";
import { TiTick } from "react-icons/ti";
import { GrLocation } from "react-icons/gr";
import { CgMenuGridR } from "react-icons/cg";
import { FaDoorOpen } from "react-icons/fa";
import product9 from "../data/product9.jpg";

import { Stacked, Pie, Button, LineChart, SparkLine } from "../components";
import CustomModal from "../components/CustomModal";
import {
  medicalproBranding,
  recentTransactionsDummy,
  weeklyStats,
  dropdownData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";

import { useStateContext } from "../contexts/ContextProvider";
import { useAdminContext } from "../contexts/AdminContext";
import { useFetchContext } from "../contexts/FetchContext";
import { usePostContext } from "../contexts/PostContext";
import ReportButton from "../components/ReportButton";
import { toast } from "react-toastify";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

const Ecommerce = () => {
  const baseUrl =
    process.env.REACT_APP_DEVELOPMENT_MODE === "DEVELOPMENT"
      ? process.env.REACT_APP_TESTING_REACT_APP_URL
      : process.env.REACT_APP_URL;
  const {
    refundedAmounts,
    revenue,
    customers,
    recentTransactions,
    weeklyStatsSale,
  } = useAdminContext();
  const { products } = useFetchContext();
  const earningData = [
    {
      icon: <MdOutlineSupervisorAccount />,
      amount: customers?.length,
      title: "Customers",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <BsBoxSeam />,
      amount: products?.length,
      title: "Products",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
    {
      icon: <FiBarChart />,
      amount: `$${revenue}`,
      title: "Total Sales",
      iconColor: "rgb(228, 106, 118)",
      iconBg: "rgb(255, 244, 229)",

      pcColor: "green-600",
    },
    {
      icon: <HiOutlineRefresh />,
      amount: `$${refundedAmounts.total}`,
      percentage: `${
        refundedAmounts.percentage !== "NaN" ? refundedAmounts.percentage : "--"
      }%`,
      title: "Refunds",
      iconColor: "rgb(0, 194, 146)",
      iconBg: "rgb(235, 250, 242)",
      pcColor: "red-600",
    },
  ];

  let sparkLineData = weeklyStatsSale?.map((item) => ({
    x: new Date(item.date).getDay(),
    yval: item.revenue,
  }));

  const [startDate, setStartDate] = React.useState(new Date().toDateString());
  const [endDate, setEndDate] = React.useState(new Date().toDateString());

  const [handleOpen, setHandleOpen] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const { currentColor, currentMode } = useStateContext();
  const handleSubmit = async () => {
    try {
      setIsLoaded(true);
      const res = await fetch(`${baseUrl}/admin/generate-report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ startDate, endDate }),
      });
      console.log(res);
      if (!res.ok) {
        setIsLoaded(false);
        setHandleOpen(false);
        return toast.error("Error generating report");
      }
      const data = await res.blob();
      const url = window.URL.createObjectURL(new Blob([data]));
      console.log("URL: ", url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report-${startDate}-${endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setHandleOpen(false);
      setIsLoaded(false);
      toast.success(res.message);
    } catch (error) {
      setIsLoaded(false);
      console.log("Error generating report: ", error);
    }
  };
  return (
    <div className="mt-24 mx-auto container">
      <div className="flex flex-wrap m-3  lg:flex-nowrap justify-center ">
        <div className="bg-white dark:text-gray-200 lg:mr-3 dark:bg-secondary-dark-bg h-44 rounded-xl w-full xl:w-96 lg:w-60 p-8 pt-9 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p style={{ color: currentColor }} className="font-bold">
                Earnings
              </p>
              <p className="text-2xl">${revenue}</p>
            </div>
            <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
            >
              <BsCurrencyDollar />
            </button>
          </div>
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-3 lg:mt-0  lg:mr-3 w-full justify-center gap-1 items-center">
          {earningData.map((item) => (
            <div
              key={item.title}
              className="bg-white flex items-center md:flex-col gap-4 md:gap-0 h-44 w-full dark:text-gray-200 dark:bg-secondary-dark-bg p-4 pt-9 rounded-2xl "
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="md:mt-3">
                <span className="text-lg font-semibold">{item.amount}</span>
                <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span>
              </p>
              <p className="text-sm text-gray-400  md:mt-1">{item.title}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col px-3 lg:flex-row w-full justify-center">
        <div className="bg-white w-full dark:text-gray-200 dark:bg-secondary-dark-bg p-4 rounded-2xl">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Revenue Updates</p>
          </div>
          <div className="mt-10 flex flex-col md:flex-row gap-10  justify-center">
            <div className=" border-b-1 w-full md:border-b-0 md:border-r-1 flex gap-2 flex-col items-center border-color m-4 md:pr-6 pb-10 md:pb-0">
              <div className="flex gap-4 xl:gap-0 flex-col xl:flex-row w-full justify-around items-center">
                <div className=" flex flex-col w-full items-center">
                  <p>
                    <span className="text-3xl font-semibold">${revenue}</span>
                  </p>
                  <p className="text-gray-500 mt-1">Total Sales</p>
                </div>
                {/* <div className="flex w-full flex-col items-center">
                  <div className=" flex items-center">
                    <p className="text-3xl font-semibold">
                      ${refundedAmounts.total}
                    </p>
                    {
                      refundedAmounts.percentage !== "NaN" && refundedAmounts.percentage(
                        <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs text-center">
                          {refundedAmounts.percentage !== "NaN" && refundedAmounts.percentage + " %"}
                        </span>
                      )}
                  </div>
                  <p className="text-gray-500 mt-1">Refunds</p>
                </div> */}
              </div>
              <div className="flex h-full w-full items-center justify-center">
                <div>
                  <ReportButton
                    color="white"
                    bgColor={currentColor}
                    text="Download Report"
                    borderRadius="10px"
                    onClick={() => {
                      setHandleOpen(true);
                    }}
                  />
                </div>

                <CustomModal
                  handleClose={() => setHandleOpen(false)}
                  open={handleOpen}
                >
                  <div className="p-2 flex flex-col cursor-default w-full">
                    <div className="h-full transition duration-300 flex flex-col w-full items-center justify-between border-black-shade dark:border-white-shade border p-4 rounded-lg">
                      <div className="flex items-center justify-between w-full">
                        {/* Month Picker from start-end */}
                        <label htmlFor="start">
                          <span className="text-sm font-semibold">
                            Start Date
                          </span>
                        </label>
                        <input
                          type="month"
                          name="start"
                          id=""
                          onChange={(e) => {
                            setStartDate(e.target.value);
                            console.log("Start Date: ", startDate);
                          }}
                        />
                        <label htmlFor="end">
                          <span className="text-sm font-semibold">
                            End Date
                          </span>
                        </label>
                        <input
                          type="month"
                          name="end"
                          id=""
                          onChange={(e) => {
                            setEndDate(e.target.value);
                            console.log("End Date: ", endDate);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex w-full justify-start items-center flex-col py-5">
                      <button
                        style={{ backgroundColor: currentColor }}
                        className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                        onClick={handleSubmit}
                        disabled={isLoaded}
                      >
                        {isLoaded ? "Generating Report..." : "Generate Report"}
                      </button>
                    </div>
                  </div>
                </CustomModal>
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <Stacked currentMode={currentMode} width="320px" height="360px" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap ">
          <div
            className=" rounded-2xl md:w-400 p-4 m-3"
            style={{ backgroundColor: currentColor }}
          >
            <div className="flex justify-between items-center ">
              <p className="font-semibold text-white text-2xl">Earnings</p>

              <div>
                <p className="text-2xl text-white font-semibold mt-8">
                  $
                  {weeklyStatsSale
                    ?.reduce((acc, item) => acc + item.revenue, 0)
                    ?.toFixed(2) || 0}
                </p>
                <p className="text-gray-200">Weekly revenue</p>
              </div>
            </div>
            {sparkLineData !== undefined ||
              (sparkLineData && (
                <div className="mt-4">
                  <SparkLine
                    currentColor={currentColor}
                    id="column-sparkLine"
                    height="100px"
                    type="Column"
                    data={sparkLineData}
                    width="320"
                    color="rgb(242, 252, 253)"
                  />
                </div>
              ))}
          </div>
          {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl md:w-400 p-8 m-3 flex justify-center items-center gap-10">
            <div>
              <p className="text-2xl font-semibold ">$43,246</p>
              <p className="text-gray-400">Yearly sales</p>
            </div>

            <div className="w-40">
              <Pie
                id="pie-chart"
                data={ecomPieChartData}
                legendVisiblity={false}
                height="160px"
              />
            </div>
          </div> */}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-10 m-4 justify-center">
        <div className="bg-white w-full lg:max-w-xl dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl">
          <div className="flex justify-between items-center gap-2">
            <p className="text-xl font-semibold">Recent Transactions</p>
          </div>
          <div className="mt-10 w-full">
            {recentTransactions?.map((item) => (
              <div key={item._id} className="flex justify-between mt-4">
                <div className="flex gap-4">
                  <button
                    type="button"
                    style={{
                      color: "#03C9D7",
                      backgroundColor: "#E5FAFB",
                    }}
                    className="text-2xl rounded-lg p-4 hover:drop-shadow-xl"
                  >
                    <BsCurrencyDollar />
                  </button>
                  <div>
                    <p className="text-md font-semibold">{item.name}</p>
                    <p className="text-sm text-gray-400">{item.email}</p>
                  </div>
                </div>
                <p className={`text-green-800`}> + ${item.amount}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white w-full dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl">
          <div className="flex justify-between items-center gap-2 mb-10">
            <p className="text-xl font-semibold">Sales Overview</p>
            <DropDown currentMode={currentMode} />
          </div>
          <div className="w-full overflow-auto">
            <LineChart />
          </div>
        </div>
      </div>

      <div className="flex m-3 px-3 flex-wrap justify-center">
        <div className="md:w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-3 ">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Weekly Stats</p>
            <button
              type="button"
              className="text-xl font-semibold text-gray-500"
            >
              <IoIosMore />
            </button>
          </div>

          <div className="mt-10 ">
            {weeklyStats.map((item) => (
              <div key={item._id} className="flex justify-between mt-4 w-full">
                <div className="flex gap-4">
                  <button
                    type="button"
                    style={{ background: item.iconBg }}
                    className="text-2xl hover:drop-shadow-xl text-white rounded-full p-3"
                  >
                    {item.icon}
                  </button>
                  <div>
                    <p className="text-md font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-400">{item.desc}</p>
                  </div>
                </div>

                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
            {/* <div className="mt-4">
              <SparkLine
                currentColor={currentColor}
                id="area-sparkLine"
                height="160px"
                type="Area"
                data={SparklineAreaData}
                width="320"
                color="rgb(242, 252, 253)"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
