import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import photo from "../data/photo-3.jpg";
import TotalOrder from "../components/TotalOrder";
import Loading from "../components/Loading";
import { useFetchContext } from "../contexts/FetchContext";
import MultiSessioned from "../components/MultiSessioned";

const TotalOrders = () => {
  const location = useLocation();
  const { userAppointments } = useFetchContext();
  const [activeTab, setActiveTab] = useState(
    location?.state?.activateTab ? location.state.activateTab : "total"
  );
  return (
    <section className="text-body-heading body-font overflow-hidden">
      <div className="container px-5 py-16 mx-auto">
        <div className="flex justify-between w-full">
          <h1 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font mb-4 tracking-wide">
            Total Appointments
          </h1>
        </div>
        <ul className="flex text-sm font-medium text-center text-body-heading border-b border-body-heading mb-10">
          <li
            onClick={() => setActiveTab("total")}
            className={`${
              activeTab === "total"
                ? "bg-body-heading text-black-shade"
                : "text-body-heading bg-transparent"
            } me-2 w-1/2 bg-body-heading cursor-pointer rounded-t-lg`}
          >
            <p
              aria-current="page"
              className="inline-block p-4 rounded-t-lg active"
            >
              Orders
            </p>
          </li>
          <li
            onClick={() => setActiveTab("multi")}
            className={`${
              activeTab === "multi"
                ? "bg-body-heading text-black-shade"
                : "text-body-heading bg-transparent"
            } me-2 w-1/2 bg-body-heading cursor-pointer rounded-t-lg`}
          >
            <p className="inline-block p-4 rounded-t-lg active">
              Multi Sessioned
            </p>
          </li>
        </ul>
        <div className="-my-8 divide-y-2 divide-body-heading">
          {!userAppointments ? (
            <div className="min-h-[20rem] w-full items-center justify-center flex">
              <Loading />
            </div>
          ) : userAppointments.length <= 0 ? (
            <div>No appointments to show</div>
          ) : (
            userAppointments.map((appointment, i) => {
              return appointment?.isMultiSession && activeTab === "multi" ? (
                <MultiSessioned
                  key={i}
                  productImage={appointment.product.images[0].url}
                  productName={appointment.product.name}
                  description={appointment.product.description}
                  availability={appointment.isAvailable}
                  status={appointment.isConfirmed}
                  cancellation={appointment.isCancelled}
                  room={appointment?.selectedRoom?.name}
                  date={appointment.date}
                  startTime={appointment.startTime}
                  endTime={appointment.endTime}
                  price={appointment.price}
                  refunded={appointment?.paymentResult?.isRefunded}
                  service={appointment?.service?.name}
                />
              ) : (
                activeTab === "total" && !appointment?.isMultiSession && (
                  <TotalOrder
                    key={i}
                    productImage={appointment.product.images[0].url}
                    productName={appointment.product.name}
                    description={appointment.product.description}
                    availability={appointment.isAvailable}
                    status={appointment.isConfirmed}
                    cancellation={appointment.isCancelled}
                    room={appointment?.selectedRoom?.name}
                    date={appointment.date}
                    startTime={appointment.startTime}
                    endTime={appointment.endTime}
                    price={appointment.price}
                    refunded={appointment?.paymentResult?.isRefunded}
                    service={appointment?.service?.name}
                  />
                )
              );
            })
          )}
        </div>
      </div>
    </section>
  );
};

export default TotalOrders;
