import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useFetchContext } from "../contexts/FetchContext";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { usePostContext } from "../contexts/PostContext";
import { useAdminContext } from "../contexts/AdminContext";
const CouponEdit = () => {
  const { currentColor } = useStateContext();
  const { fetchSingleCoupon } = useAdminContext();
  const { UpdateCouponData, PostCouponData } = usePostContext();
  const navigate = useNavigate();
  const [couponDetails, setCouponDetails] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      let data = await fetchSingleCoupon(id);
      setCouponDetails(data);
      formik.setFieldValue("code", data.code);
      formik.setFieldValue("isValid", data.isValid);
    })();
  }, []);

  const handleSubmit = async (values) => {
    setBtnLoading(true);
    await UpdateCouponData(values, id);
    formik.resetForm();
    setBtnLoading(false);
    navigate("/admin/coupons");
  };
  function couponGenerator() {
    var coupon = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 6; i++) {
      coupon += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    let code = coupon.toLocaleUpperCase();
    formik.setFieldValue("code", code);
  }

  const CouponUpdated = Yup.object({
    code: Yup.string()
      .min(6, "code should be 6 characters long")
      .max(6, "code should be 6 characters long")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      isValid: "",
    },
    validationSchema: CouponUpdated,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1
          style={{ color: currentColor }}
          className="text-3xl text-center font-medium title-font mb-10 tracking-widest"
        >
          Edit Coupon
        </h1>
        <div className=" mx-auto flex flex-wrap">
          <div className=" w-full mb-6 ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex-col mx-auto max-w-xl"
            >
              <div className="flex items-center gap-5">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="code"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Coupon Code
                  </label>
                  {formik.touched.code && formik.errors.code && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.code}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  onClick={couponGenerator}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade text-nowrap dark:text-white focus:outline-none disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Generate Code
                </button>
              </div>
              <div className="flex items-center gap-5">
                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="isValid" className="text-sm">
                    Valid
                  </label>
                  <select
                    id="isValid"
                    value={formik.values.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                  >
                    <option
                      className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                      value={true}
                    >
                      Yes
                    </option>
                    <option
                      className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                      value={false}
                    >
                      No
                    </option>
                  </select>
                  {formik.touched.isValid && formik.errors.isValid && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.isValid}
                    </div>
                  )}
                </div>
              </div>
              <div className=" w-full my-2 flex justify-end ">
                <button
                  type="submit"
                  disabled={btnLoading ? true : false}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade dark:text-white focus:outline-none disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  {btnLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CouponEdit;
