import React, { useState, useEffect } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useAdminContext } from "../contexts/AdminContext";
import { useStateContext } from "../contexts/ContextProvider";
import moment from "moment";
import { usePostContext } from "../contexts/PostContext";
import axios from "axios";
import CustomModal from "../components/CustomModal";
import { toast } from "react-toastify";
const AppointmentDetail = () => {
  const { user, logout } = useAuthContext();
  const { fetchSingleAppointment } = useAdminContext();
  const { UpdateUserAppointment, ReturnAppointmentPayment, makeReturnPayment, CancelMultiSession } =
    usePostContext();
  const { currentColor, currentMode } = useStateContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [appointmentStatus, setAppointmentStatus] = useState({
    isConfirmed: "",
    isCancelled: "",
  });
  const [updateButtonStatus, setUpdateButtonStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const fetchAppointment = async () => {
    let data = await fetchSingleAppointment(id);
    setAppointmentDetails(data);
    setAppointmentStatus({
      isConfirmed: data.isConfirmed,
      isCancelled: data.isCancelled,
    });
  };

  useEffect(() => {
    fetchAppointment();
  }, []);

  useEffect(() => {
    if (
      appointmentStatus?.isConfirmed !== appointmentDetails?.isConfirmed ||
      appointmentStatus?.isCancelled !== appointmentDetails?.isCancelled
    ) {
      return setUpdateButtonStatus(false);
    }
    return setUpdateButtonStatus(true);
  }, [
    appointmentStatus.isConfirmed,
    appointmentStatus.isCancelled,
    appointmentDetails,
  ]);

  const handleSubmit = async () => {
    setLoading(true);
    setUpdateButtonStatus(true);
    await UpdateUserAppointment(appointmentStatus, id);
    setAppointmentDetails(null);
    setLoading(false);
    fetchAppointment();
  };

  const makeCancelMultiSession = async () => {
    setLoading(true);
    setUpdateButtonStatus(true);
    await CancelMultiSession(id);
    setAppointmentDetails(null);
    setLoading(false);
    fetchAppointment();
  }
  const refundAmount = async () => {
    handleClose();
    setRefundLoading(true);
    let data = {
      paymentIntentId: appointmentDetails.paymentResult.id,
    };
    await ReturnAppointmentPayment(data);
    await makeReturnPayment(id);
    setRefundLoading(false);
  };

  return (
    <div className="container px-5 py-16 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {appointmentDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : appointmentDetails !== null && appointmentDetails.product === null ? (
        <div>Product has Been Deleted</div>
      ) : (
        <section className="text-black-shade dark:text-white-shade body-font">
          {(appointmentDetails?.isCancelled &&
            !appointmentDetails?.paymentResult?.isRefunded &&
            appointmentDetails?.paymentResult?.id !== null
            && appointmentDetails?.isMultiSession === false
          )
            && (
              <div
                className="p-4 mb-4 text-sm text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
                role="alert"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <span className="font-medium">
                      Appointment has been cancelled!
                    </span>{" "}
                    Click on refund to refund the amount
                  </div>
                  <div>
                    <button
                      onClick={handleOpen}
                      disabled={refundLoading}
                      style={{ backgroundColor: currentColor }}
                      className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                    >
                      {refundLoading ? "Refunding..." : "Refund"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          <div className="flex flex-wrap">
            <div className="flex flex-col mb-5 lg:items-start items-center">
              <div className="flex-grow w-full">
                <h2
                  style={{ color: currentColor }}
                  className=" text-xl text-left title-font font-medium mb-3"
                >
                  Appointment Statuses
                </h2>
              </div>
            </div>
            {appointmentDetails?.paymentResult?.isRefunded ? (
              <div
                className="p-4 mb-4 w-full text-sm text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
                role="alert"
              >
                <span className="font-medium">
                  Appointment has been cancelled!
                </span>{" "}
                The amount has been refunded
              </div>
            ) : (
              <>
                <div className="w-full mx-auto mb-8 rounded-md overflow-auto">
                  <table className="table-auto w-full text-left whitespace-no-wrap">
                    <thead>
                      <tr>
                        <th
                          style={{ backgroundColor: currentColor }}
                          className="px-4 py-3 title-font tracking-wider font-medium text-sm rounded-tl rounded-bl"
                        >
                          Confirmed
                        </th>
                        <th
                          style={{ backgroundColor: currentColor }}
                          className="px-4 py-3 title-font tracking-wider font-medium text-sm rounded-tr rounded-br"
                        >
                          Cancelled
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ borderColor: currentColor }}
                          className="px-4 border-b-2 py-3 bg-white dark:bg-secondary-dark-bg"
                        >
                          <select
                            value={appointmentStatus.isConfirmed}
                            onChange={(e) =>
                              setAppointmentStatus({
                                ...appointmentStatus,
                                isConfirmed: JSON.parse(e.target.value),
                              })
                            }
                            className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                          >
                            <option
                              className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                              value={true}
                            >
                              Yes
                            </option>
                            <option
                              className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                              value={false}
                            >
                              No
                            </option>
                          </select>
                        </td>
                        <td
                          style={{ borderColor: currentColor }}
                          className="px-4 border-b-2 py-3 bg-white dark:bg-secondary-dark-bg"
                        >
                          <select
                            value={appointmentStatus.isCancelled}
                            onChange={(e) =>
                              setAppointmentStatus({
                                ...appointmentStatus,
                                isCancelled: JSON.parse(e.target.value),
                              })
                            }
                            className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                          >
                            <option
                              className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                              value={true}
                            >
                              Yes
                            </option>
                            <option
                              className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                              value={false}
                            >
                              No
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-8 text-right">
                    {
                      !appointmentDetails.isMultiSession && (
                        <button
                          onClick={handleSubmit}
                          style={{ backgroundColor: currentColor }}
                          disabled={updateButtonStatus}
                          className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                        >
                          {loading ? "Updating..." : "Update"}
                        </button>
                      )
                    }
                    {
                      appointmentDetails?.isMultiSession && (
                        <button
                          onClick={makeCancelMultiSession}
                          style={{ backgroundColor: currentColor }}
                          disabled={appointmentDetails?.isCancelled || loading}
                          className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                        >
                          {loading ? "Cancelling..." : "Cancel"}
                        </button>
                      )
                    }
                  </div>
                </div>
              </>
            )}
            <div className="lg:w-1/2 w-full bg-black-shade mb-10 lg:mb-0 max-h-96 rounded-lg overflow-hidden">
              <img
                alt="feature"
                className="object-contain  h-full w-full"
                src={appointmentDetails?.product?.images[0]?.url}
              />
            </div>
            <div className="flex flex-col flex-wrap -mb-10 lg:w-1/2 lg:pl-10 lg:text-left text-center">
              <div className="flex flex-col mb-5 lg:items-start items-center">
                <div className="flex-grow w-full">
                  <h2
                    style={{ color: currentColor }}
                    className=" text-xl text-left title-font font-medium mb-3"
                  >
                    Product Details
                  </h2>
                  <div className="grid grid-cols-2 text-sm gap-2 w-full">
                    <div className="flex w-full justify-start">
                      Product Name
                    </div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.product.name}
                    </div>
                    <div className="flex w-full justify-start">
                      Product Description
                    </div>
                    <div className="text-left flex w-full justify-start">
                      <p className="line-clamp-2">
                        {appointmentDetails.product.description}
                      </p>
                    </div>
                    <div className="flex w-full justify-start">Room</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.product.room.filter(
                        ({ _id }) => _id === appointmentDetails.selectedRoom
                      )[0]?.name || "--"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-5 lg:items-start items-center">
                <div className="flex-grow w-full">
                  <h2
                    style={{ color: currentColor }}
                    className=" text-xl text-left title-font font-medium mb-3"
                  >
                    Appointment Details
                  </h2>
                  <div className="grid grid-cols-2 text-sm gap-2 w-full">
                    <div className="flex w-full justify-start">Date</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.date}
                    </div>
                    <div className="flex w-full justify-start">Start Time</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.startTime}
                    </div>
                    <div className="flex w-full justify-start">End Time</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.endTime}
                    </div>
                    <div className="flex w-full justify-start">Price</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.price} $
                    </div>
                    <div className="flex w-full justify-start">Payment</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.isPaid ? "Paid" : "Unpaid"}
                    </div>
                    <div className="flex w-full justify-start">
                      Availability
                    </div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.isAvailable ? "Yes" : "No"}
                    </div>
                    <div className="flex w-full justify-start">
                      Order Number
                    </div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.paymentResult.id}
                    </div>
                    {appointmentDetails.service?.name ? (
                      <>
                        <div className="flex w-full justify-start">
                          Service Name
                        </div>
                        <div className="flex w-full justify-start">
                          {appointmentDetails.service?.name}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-5 lg:items-start items-center">
                <div className="flex-grow w-full">
                  <h2
                    style={{ color: currentColor }}
                    className=" text-xl text-left title-font font-medium mb-3"
                  >
                    Client Details
                  </h2>
                  <div className="grid grid-cols-2 text-sm gap-2 w-full">
                    <div className="flex w-full justify-start">Name</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.client.name}
                    </div>
                    <div className="flex w-full justify-start">Email</div>
                    <div className="flex w-full justify-start">
                      {appointmentDetails.client.email}
                    </div>
                    {appointmentDetails.client.isWalkinCustomer && (
                      <>
                        <div className="flex w-full justify-start">
                          Walk-in Password
                        </div>
                        <div className="flex w-full justify-start">
                          {appointmentDetails.client.walkinPassword}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomModal open={open} handleClose={handleClose}>
            <div className="w-full flex flex-col">
              <div className="py-4 font-semibold">
                Are you sure want to Refund Amount ?
              </div>
              <div className="ml-auto font-semibold">
                <button
                  onClick={handleClose}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                >
                  No
                </button>
                <button
                  onClick={refundAmount}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Yes
                </button>
              </div>
            </div>
          </CustomModal>
        </section>
      )}
    </div>
  );
};

export default AppointmentDetail;
