const MultiSessioned = ({
  productImage,
  productName,
  description,
  cancellation,
  room,
  date,
  startTime,
  endTime,
  price,
  refunded,
  service,
}) => {
  return (
    <div className="py-8 flex flex-col relative h-full md:max-h-96 md:flex-row w-full">
      {cancellation && (
        <div className=" pb-16 flex items-center text-2xl justify-center absolute backdrop-blur-sm md:max-h-96 h-full min-h-max w-full rounded-md">
          <span className="bg-body-heading text-black-shade p-5 rounded-lg">
            {refunded
              ? "Cancelled and Amount has been refunded"
              : "Cancelled Your Amount Will be Refunded Soon"}
          </span>
        </div>
      )}
      <div className="w-full md:w-1/2 md:mb-0 mb-6">
        <div className="w-full bg-black-shade rounded-md min-h-full md:max-h-full max-h-64 flex">
          <img src={productImage} className=" w-full object-contain" />
        </div>
      </div>

      <div className="md:ml-3 rounded-md bg-black-shade text-white-shade px-4 py-2 m-0 w-full">
        <h2 className="text-2xl md:text-3xl text-body-heading font-medium title-font line-clamp-1 my-2">
          {productName}
        </h2>
        <h3 className="text-base md:text-xl font-medium max-w-96 line-clamp-2 title-font my-2">
          {description}
        </h3>
        <div className="flex text-xs md:text-sm text-body-heading flex-col">
          <div className="grid grid-cols-2 md:grid-cols-3">
            <div>Room</div>
            <div className="md:col-span-2">{room || "--"}</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3">
            <div>Date</div>
            <div className="md:col-span-2">{date.slice(0, 10)}</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3">
            <div>Start Time</div>
            <div className="md:col-span-2">{startTime}</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3">
            <div>End Time</div>
            <div className="md:col-span-2">{endTime}</div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3">
            <div>Price</div>
            <div className="md:col-span-2">Redeemed From Multi Session</div>
          </div>
          {service !== undefined || "" || null ? (
            <div className="grid grid-cols-2 md:grid-cols-3">
              <div>Service</div>
              <div className="md:col-span-2">{service}</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MultiSessioned;
