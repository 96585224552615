import React from "react";
import { Modal, Fade, Backdrop } from "@mui/material";

const CustomModal = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            border: "2px solid #000",
            padding: "20px 20px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            outline: "none",
            maxWidth: "672px",
            width: "100%",
          }}
        >
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
