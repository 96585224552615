import React from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import ActiveService from "../components/ActiveService";
import Loading from "../components/Loading";
import { useFetchContext } from "../contexts/FetchContext";

const ActiveServices = () => {
  const { user } = useAuthContext();
  const { userAppointments, userServices } = useFetchContext();
  const navigate = useNavigate();

  return (
    <section className="text-body-heading body-font overflow-hidden">
      <div className="container px-5 py-16 mx-auto">
        <div className="flex flex-col  md:flex-row  flex-wrap -m-4">
          {userServices === null ? (
            <div className="min-h-[20rem] w-full items-center justify-center flex">
              <Loading />
            </div>
          ) : userServices.length <= 0 ? (
            <div>No active service available.</div>
          ) : (
            userServices.map(({ product, service, serviceCount }, i) => {
              return (
                <ActiveService
                  key={i}
                  userServiceId={service.serviceId}
                  serviceId={service._id}
                  serviceCount={serviceCount}
                  productImage={product.image.url}
                  productId={product._id}
                  productName={product.name}
                  serviceName={service.name || "Unavailable"}
                />
              );
            })
          )}
        </div>
      </div>
    </section>
  );
};

export default ActiveServices;
