import moment from "moment";
import { useStateContext } from "../contexts/ContextProvider";
const UserCard = ({ title, value, icon }) => {
  const { currentColor } = useStateContext();
  return (
    <div className="flex w-full items-center justify-center">
      <div className="rounded-md w-96 border dark:text-white-shade text-black-shade dark:bg-secondary-dark-bg dark:border-neutral-800 border-white-shade bg-white p-8 text-center shadow">
        <div
          className=" mx-auto flex h-12 w-12 items-center justify-center rounded-md"
          style={{ backgroundColor: currentColor }}
        >
          {icon}
        </div>
        <h3 className="mt-6 ">{title}</h3>
        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide ">
          {value}
        </p>
      </div>
    </div>
  );
};

export default UserCard;
