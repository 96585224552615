import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "../css/calendar.css";
import moment from "moment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ShopTimeSlot from "./Shop/Components/ShopTimeSlot";
import { useAuthContext } from "../contexts/AuthContext";
import { useFetchContext } from "../contexts/FetchContext";
import Loading from "../components/Loading";
import AppointmentDetails from "./Shop/Components/AppointmentDetails";
import { useCartContext } from "../contexts/CartContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useStateContext } from "../contexts/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { usePostContext } from "../contexts/PostContext";

const AppointService = () => {
  const [searchParams] = useSearchParams();
  const { fetchSingleProduct, fetchAvailableSlots } = useFetchContext();
  const { PostAppointmentData, isLoading } = usePostContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const [currentWeekDates, setCurrentWeekDates] = useState([]);
  const [productDetails, setProductDetails] = useState("");
  const [currentService, setCurrentService] = useState("");
  const [serviceUnavailable, setServiceUnavailable] = useState(false);
  const [slotData, setSlotData] = useState(null);
  const [slotLoading, setSlotLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { id } = useParams();
  const serviceId = searchParams.get("sid");
  const userServiceId = searchParams.get("usid");

  const checkUserServiceCount = () => {
    let userServiceCount = user.userServices.find(
      (userService) => userService._id == userServiceId
    )?.serviceCount;
    if (!userServiceCount || userServiceCount <= 0) {
      return false;
    } else {
      return true;
    }
  };
  //FETCH DETAILS EFFECT
  useEffect(() => {
    user &&
      (async () => {
        let { data, success } = await fetchSingleProduct(id);
        if (success && checkUserServiceCount()) {
          setProductDetails(data);
          let serviceDetails = data.services.filter(
            (service) => service._id == serviceId
          )[0];
          setCurrentService(serviceDetails);
          formik.setFieldValue("product", data._id);
          formik.setFieldValue("selectedRoom", data.room[0]._id);
          formik.setFieldValue("price", serviceDetails.price);
          user && setPageLoading(false);
        } else {
          setPageLoading(false);
          setServiceUnavailable(true);
        }
      })();
  }, [user]);
  //EFFECT To GET AVAILABLE DATES FOR CALENDAR
  useEffect(() => {
    let dates = [];
    let currentDate = moment().startOf("day"); // Start from the beginning of today
    for (let i = 0; i < 7; i++) {
      dates.push(currentDate.format("YYYY-M-DD")); // Push formatted date to array
      currentDate.add(1, "day"); // Move to next day
    }
    setCurrentWeekDates(dates);
  }, []);

  const handleAppointment = async (values) => {
    setBtnLoading(true);
    let dataToSend = {
      ...values,
      date: values.date,
      newPurchase: false,
    };
    await PostAppointmentData(dataToSend);
    formik.resetForm();
    formik.setFieldValue("product", productDetails._id);
    formik.setFieldValue("paymentMethod", "card");
    formik.setFieldValue("price", currentService.price);
    formik.setFieldValue("serviceId", serviceId);
    setSlotData(null);
    setBtnLoading(false);
    navigate("/profile/total-orders", { state: { activateTab: "multi" } });
  };

  const handleRoomChange = async (room) => {
    setSlotData(null);
    formik.setFieldValue("selectedRoom", room);
    formik.setFieldValue("date", null);
    formik.setFieldValue("startTime", null);
    formik.setFieldValue("endTime", null);
  };

  const handleDateChange = async (date) => {
    setSlotLoading(true);
    let formattedDate = moment(date).format("YYYY-M-DD");
    let dateForData = moment(date).format("YYYY-MM-DD");
    let dataToSend = {
      productId: id,
      selectedDate: dateForData,
      roomId: formik.values.selectedRoom,
    };
    let data = await fetchAvailableSlots(dataToSend);
    setSlotData(data);
    formik.setFieldValue("date", formattedDate);
    formik.setFieldValue("startTime", null);
    formik.setFieldValue("endTime", null);
    setSlotLoading(false);
  };
  const handleTimeChange = async (time) => {
    const startTime = moment(time, "hh:mma");
    const endTime = productDetails?.name?.includes("Red Light Therapy")
      ? startTime.add(30, "minutes")
      : startTime.add(50, "minutes");
    const formattedEndTime = endTime.format("hh:mma");
    formik.setFieldValue("startTime", time);
    formik.setFieldValue("endTime", formattedEndTime);
  };

  const AppointmentSchema = Yup.object({
    date: Yup.string().required("Please Select Date"),
    startTime: Yup.string().required("Please Select Time"),
  });

  const formik = useFormik({
    initialValues: {
      product: "",
      date: null,
      startTime: null,
      endTime: null,
      paymentMethod: "card",
      price: "",
      serviceId: serviceId,
      selectedRoom: "",
      isMultiSession: true,
    },
    validationSchema: AppointmentSchema,
    onSubmit: (values) => {
      handleAppointment(values);
    },
  });

  return (
    <section className="text-body-text bg-product-body-bg body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-col flex-wrap">
          {!user || pageLoading ? (
            <Loading />
          ) : serviceUnavailable ? (
            <div className="container px-5  py-24 mx-auto">
              <div className="flex w-full items-center justify-center">
                <h2 className="text-4xl">Service Unavailable or Used</h2>
              </div>
            </div>
          ) : (
            productDetails && (
              <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="flex flex-wrap w-full">
                  <div className="lg:w-1/2 w-full">
                    <Carousel
                      showArrows={false}
                      dynamicHeight={false}
                      infiniteLoop={true}
                      interval={5000}
                      autoPlay={true}
                      showIndicators={false}
                      showStatus={false}
                      thumbWidth={"100px"}
                      renderThumbs={() =>
                        productDetails.images.map((image, i) => (
                          <div
                            key={i}
                            className="h-28 border-black-shade border-2 bg-white-shade"
                          >
                            <img
                              src={image.url}
                              className="h-full w-full object-cover"
                            />
                          </div>
                        ))
                      }
                    >
                      {productDetails.images.map((image) => (
                        <div
                          className=" w-full lg:max-h-[30rem] max-h-96 bg-black-shade h-full rounded"
                          key={image._id}
                        >
                          <img
                            className=" w-full h-full object-contain rounded"
                            src={image.url}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className="lg:w-1/2 w-full justify-around max-h-[30rem] min-h-max flex flex-col lg:pl-10 lg:py-4 ">
                    <h1 className="text-body-text text-4xl mt-3 lg:mt-0 title-font font-medium mb-1">
                      {productDetails.name}
                    </h1>
                    <div className="flex my-4">
                      <span className="title-font font-medium text-3xl text-body-heading">
                        ${formik.values.price}
                      </span>
                    </div>
                    <p className="my-5 leading-relaxed">
                      {productDetails.description}
                    </p>
                    <div className="flex flex-col mt-6 items-center pb-5  ">
                      <div className="flex flex-col md:flex-row ml-2 w-full items-center">
                        <span className="md:mr-3 mb-2 md:mb-0 w-full md:w-1/6 lg:w-1/4 whitespace-nowrap">
                          Service
                        </span>
                        <div className="relative right-0 w-full">
                          <input
                            disabled
                            readOnly
                            value={currentService?.name}
                            className="rounded border w-full border-gray-700 focus:border-body-heading bg-transparent appearance-none py-2 focus:outline-none text-body-heading pl-3 pr-10"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row ml-2 mt-4 w-full items-center">
                        <span className="md:mr-3 mb-2 md:mb-0 w-full md:w-1/6 lg:w-1/4 whitespace-nowrap">
                          Room
                        </span>
                        <div className="relative right-0 w-full">
                          <select
                            name="selectedRoom"
                            value={formik.values.selectedRoom}
                            onChange={(e) => handleRoomChange(e.target.value)}
                            className="rounded border w-full border-gray-700 focus:border-body-heading bg-transparent appearance-none py-2 focus:outline-none text-body-heading pl-3 pr-10"
                          >
                            {productDetails.room?.map((room) => (
                              <option
                                key={room._id}
                                value={room._id}
                                className="py-2 px-1"
                              >
                                {room.name}
                              </option>
                            ))}
                          </select>
                          <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                            <svg
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className="w-4 h-4"
                              viewBox="0 0 24 24"
                            >
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative mt-3">
                  <div className="mt-8 px-2 flex-col lg:flex-row flex w-full flex-wrap">
                    <div className=" min-h-max w-full lg:w-3/5 ">
                      <Calendar
                        tileClassName="p-2 sm:p-3 text-sm sm:text-base bg-gray-100"
                        next2Label={null}
                        prev2Label={null}
                        onChange={(value, e) => {
                          handleDateChange(value);
                        }}
                        className="min-h-max rounded-sm border flex flex-col border-black overflow-hidden"
                        value={formik.values.date}
                        minDate={new Date()}
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className="mt-3 text-body-heading font-semibold">
                          {formik.errors.date}
                        </div>
                      )}
                    </div>

                    <div className="lg:pl-3 lg:mt-0 mt-4 w-full lg:w-2/5">
                      <div className="w-full min-h-max h-full flex flex-col">
                        <div className="h-full p-6 rounded-sm bg-white-shade border border-black-shade flex relative overflow-hidden">
                          {slotLoading ? (
                            <div className="h-full w-full flex items-center justify-center ">
                              <Loading
                                variant={"round"}
                                color={"#c78c4e"}
                                bgColor={"black"}
                                width="2rem"
                              />
                            </div>
                          ) : slotData === null ? (
                            <div className="h-full w-full flex items-center justify-center ">
                              <p className="text-body-heading font-semibold  text-sm">
                                Choose date to see available slots
                              </p>
                            </div>
                          ) : slotData.length <= 0 ? (
                            <div className="h-full flex items-center justify-center ">
                              <p className="text-body-heading font-semibold text-sm">
                                No available slots
                              </p>
                            </div>
                          ) : (
                            <div className="h-full w-full grid grid-cols-3">
                              {slotData.map((time, i) => (
                                <ShopTimeSlot
                                  key={i}
                                  onClick={() => handleTimeChange(time)}
                                  value={formik.values.startTime}
                                  time={time}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                        {formik.touched.startTime &&
                          formik.errors.startTime && (
                            <div className="mt-3 text-body-heading font-semibold">
                              {formik.errors.startTime}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full px-5 lg:px-20 mt-4">
                  <AppointmentDetails
                    productDetails={productDetails}
                    service={formik.values.serviceId}
                    room={formik.values.selectedRoom}
                    date={formik.values.date}
                    startTime={formik.values.startTime}
                    endTime={formik.values.endTime}
                    totalAmount={currentService?.price}
                  />
                </div>
                <div className="w-full flex gap-4 px-20 mt-4">
                  <button
                    type="submit"
                    disabled={btnLoading ? true : false}
                    className="w-full items-center transition-all duration-300 bg-btn-color border-0 py-3 text-white-shade px-3 disabled:bg-body-heading-hover focus:outline-none hover:bg-body-heading-hover rounded text-lg font-bold mt-4 md:mt-0"
                  >
                    {btnLoading ? "Processing..." : "Create Appointment"}
                  </button>
                </div>
              </form>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default AppointService;
