import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { MdFullscreen, MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";

const AdminProducts = ({ image, link, name, description }) => {
  const { currentColor } = useStateContext();
  return (
    <div className="p-4 lg:w-1/2">
      <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
        <img
          alt="team"
          className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
          src={image}
        />
        <div className="flex-grow sm:pl-8">
          <h2 className="title-font font-semibold text-lg mb-3 ">{name}</h2>
          <p className=" line-clamp-4 mb-4">{description}</p>
          <span className="flex justify-between items-center pr-3">
            <Link
              to={`/admin/product/${link}`}
              style={{ backgroundColor: currentColor }}
              className="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
            >
              View
              <MdFullscreen className="ml-1" />
            </Link>
            <Link
              to={`/admin/edit-product/${link}`}
              style={{ backgroundColor: currentColor }}
              className="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
            >
              Edit
              <MdEdit className="ml-1" />
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;
