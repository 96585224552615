import React, { useState, useEffect } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import photo from "../data/logo.png";
import { useAdminContext } from "../contexts/AdminContext";
import UserOrder from "../components/UserOrder";
import UserCard from "../components/UserCard";
import { IoReceiptOutline } from "react-icons/io5";
import { TbSquareCheck } from "react-icons/tb";
import { useStateContext } from "../contexts/ContextProvider";

const CustomerDetail = () => {
  const { user, logout } = useAuthContext();
  const { fetchSingleUser } = useAdminContext();
  const { currentMode } = useStateContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    (async () => {
      let data = await fetchSingleUser(id);
      setUserDetails(data);
    })();
  }, []);

  console.log(userDetails)



  return (
    <div className="container px-5 py-24 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {userDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="profile"
            src={
              userDetails.avatar?.url
                ? userDetails.avatar?.url
                : photo
            }
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium ">
              {userDetails.name}
            </h1>
            <p className="leading-relaxed mb-8">{userDetails.email}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full mb-8">
            <UserCard
              value={userDetails.appointments.length}
              title="No. Of Orders"
              icon={<IoReceiptOutline />}
            />
            {
              userDetails.userServices?.length > 0 && (
                userDetails.userServices.map((service, i) => (
                  <div key={i}>
                    <UserCard
                      value={service.type.toUpperCase()}
                      title={`Remaining Sessions ${service.serviceCount}`}
                      icon={<TbSquareCheck />}
                    />
                  </div>
                )
                ))
            }
          </div>

          <div className="flex justify-between w-full mb-4">
            <h1 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              User Orders
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full">
            {userDetails.appointments.length > 0 ? (
              userDetails.appointments.map((appointment, i) => (
                <UserOrder
                  key={i}
                  appointmentId={appointment._id}
                  productName={appointment.product.name}
                  description={appointment.product.description}
                  availability={appointment.isAvailable}
                  status={appointment.isConfirmed}
                  cancellation={appointment.isCancelled}
                  room={appointment.product.room.name}
                  date={appointment.date}
                  startTime={appointment.startTime}
                  endTime={appointment.endTime}
                  price={appointment.price}
                />
              ))
            ) : (
              <div className="flex w-full col-span-3 items-center text-black-shade dark:text-white-shade justify-center">
                <h2 className=" mt-4 mb-8 text-2xl">No Orders Found</h2>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDetail;
