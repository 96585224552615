import React from "react";
import { Link } from "react-router-dom";
import { BsHouse } from "react-icons/bs";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import EnableSlot from "../components/EnableSlot";
import Loading from "../components/Loading";
import { useAdminContext } from "../contexts/AdminContext";
const EnableSlots = () => {
  const { currentColor, currentMode } = useStateContext();
  const { disableSlots } = useAdminContext();
  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font  tracking-widest">
            Disabled Slots
          </h1>
        </div>
        {disableSlots === null ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading
              color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
              bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
            />
          </div>
        ) : disableSlots !== null && disableSlots.length < 1 ? (
          <div className="h-full w-full flex items-center justify-center ">
            <p className="text-black-shade dark:text-white-shade font-semibold py-2 text-sm">
              No disbaled slots
            </p>
          </div>
        ) : (
          <div className="flex flex-wrap -m-2">
            {disableSlots?.map((slot) => {
              const {
                disabledSlots: {
                  slots: { startTime, endTime },
                  date,
                  productId: { name: productName },
                  roomId: { name: roomName },
                },
              } = slot;
              return (
                <EnableSlot
                  key={slot._id}
                  id={slot._id}
                  productName={productName}
                  roomName={roomName}
                  date={date}
                  startTime={startTime}
                  endTime={endTime}
                />
              );
            })}
          </div>
        )}

        {/* {disableSlots.length <= 0 && (
          <div className="h-full w-full flex items-center justify-center ">
            <p className="text-black-shade dark:text-white-shade font-semibold py-2 text-sm">
              No slots to enable
            </p>
          </div>
        )} */}
      </div>
    </section>
  );
};

export default EnableSlots;
