import React from "react";

const ShopTimeSlot = ({ time, onClick, value }) => {
  return (
    <div
      onClick={onClick}
      className={`p-1 sm:p-2 cursor-pointer duration-300 transition ${
        value === time
          ? " text-black-shade bg-white-shade   border-black-shade"
          : "text-white-shade bg-black-shade border-white-shade"
      } rounded-sm hover:bg-white-shade hover:border-black-shade hover:text-black-shade items-center flex border`}
    >
      <span className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2 inline-flex items-center justify-center bg-body-heading rounded-full flex-shrink-0">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          className="w-2 h-2 sm:h-3 sm:w-3"
          viewBox="0 0 24 24"
        ></svg>
      </span>
      <p className="text-xs md:text-sm lg:text-base">{time}</p>
    </div>
  );
};

export default ShopTimeSlot;
