import React, { useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { BsHouse } from "react-icons/bs";
import { MdEdit, MdDelete } from "react-icons/md";
import { Modal, Fade, Backdrop } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import CustomModal from "./CustomModal";
import { Link } from "react-router-dom";
const Coupon = ({ id, couponCode, discount, type, isValid, countUsed }) => {
  const { currentColor, currentMode } = useStateContext();
  const { DeleteCouponData } = usePostContext();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const deleteCoupon = async () => {
    handleDeleteClose();
    await DeleteCouponData(id);
  };

  return (
    <>
      <CustomModal open={deleteOpen} handleClose={handleDeleteClose}>
        <div className="w-full flex flex-col">
          <div className="py-4 font-semibold">
            Are you sure want to delete this coupon ?
          </div>
          <div className="ml-auto font-semibold">
            <button
              onClick={handleDeleteClose}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
            >
              No
            </button>
            <button
              onClick={deleteCoupon}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Yes
            </button>
          </div>
        </div>
      </CustomModal>
      <div className="p-2 flex flex-col cursor-default md:w-1/2 w-full">
        <div className="h-full transition duration-300 flex flex-col w-full items-center justify-between border-black-shade dark:border-white-shade border p-4 rounded-lg">
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col">
              <div className=" flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font text-sm font-medium"
                >
                  {couponCode}
                </span>
              </div>
              <div className=" flex-grow">
                <h2
                  style={{ color: currentColor }}
                  className="title-font font-medium"
                >
                  {discount}
                  {type === "fixed" ? "$" : "%"}
                </h2>
              </div>
              <div className=" flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font text-sm font-medium"
                >
                  Status : {isValid ? "Valid" : "Not Valid"}
                </span>
              </div>

              <div className="flex-grow">
                <span
                  style={{ color: currentColor }}
                  className="title-font text-sm font-medium"
                >
                  Used : {countUsed}
                </span>
              </div>
            </div>
            <div className="flex gap-2 flex-col">
              <Link
                to={`/admin/edit-coupon/${id}`}
                className="h-6 w-6 flex transition duration-300 items-center justify-center rounded-full hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
              >
                <MdEdit className="h-4 w-4" />
              </Link>
              <button
                onClick={handleDeleteOpen}
                className="h-6 w-6 flex transition duration-300 items-center justify-center rounded-full hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
              >
                <MdDelete className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coupon;
