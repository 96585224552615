import React, { useState } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import CustomModal from "../components/CustomModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
const ProfileSettings = () => {
  const { user, logout } = useAuthContext();
  const {
    UpdateProfile,
    UpdatePassword,
    AddPhoneNumber,
    UpdatePhoneNumber,
    isLoading,
  } = usePostContext();
  const navigate = useNavigate();
  const [nameModal, setNameModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [phoneNoModal, setPhoneNoModal] = useState(false);
  const handleOpen = (modalName) => {
    if (modalName === "name") {
      setNameModal(true);
    } else if (modalName === "email") {
      setEmailModal(true);
    } else if (modalName === "password") {
      setPasswordModal(true);
    } else {
      setPhoneNoModal(true);
    }
  };
  const handleClose = (modalName) => {
    if (modalName === "name") {
      setNameModal(false);
    } else if (modalName === "email") {
      setEmailModal(false);
    } else if (modalName === "password") {
      setPasswordModal(false);
    } else {
      setPhoneNoModal(false);
    }
  };

  const handleSubmit = (values, submission) => {
    switch (submission) {
      case "name":
        (async () => {
          await UpdateProfile(values);
          handleClose("name");
        })();
        break;
      case "email":
        (async () => {
          await UpdateProfile(values);
          handleClose("email");
        })();
        break;
      case "password":
        (async () => {
          await UpdatePassword(values);
          handleClose("password");
        })();
        break;
      case "phoneNumber":
        (async () => {
          values.phoneNumber = "+1" + values.phoneNumber;
          user?.phoneNumber
            ? await UpdatePhoneNumber(values)
            : await AddPhoneNumber(values);
          handleClose("phoneNumber");
        })();
        break;
      default:
        return;
    }
  };

  //FORM SCHEMA FOR FORMIK-NAME
  const ChangeName = Yup.object({
    name: Yup.string()
      .min(3, "Minimum 3 Characters")
      .test(
        "is-different-email",
        "New name must be different from the original name",
        function (value) {
          return value !== user.name;
        }
      )
      .required("Name is Required"),
  });
  //FORM SCHEMA FOR FORMIK-EMAIL
  const ChangeEmail = Yup.object({
    email: Yup.string()
      .email("Invalid Email")
      .test(
        "is-different-email",
        "New email must be different from the original email",
        function (value) {
          return value !== user.email;
        }
      )
      .required("Email is Required"),
  });
  //FORM SCHEMA FOR FORMIK-PASSWORD
  const ChangePassword = Yup.object({
    oldPassword: Yup.string().required("Old Password is Required"),
    newPassword: Yup.string()
      .notOneOf(
        [Yup.ref("oldPassword")],
        "New Password must be different from Old Password"
      )
      .min(6, "Minimum 6 Characters")
      .required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New Password and Confirm Password must be same"
      )
      .required("Confirm Password is Required"),
  });
  //FORM SCHEMA FOR FORMIK-EMAIL
  const ChangePhoneNo = Yup.object({
    phoneNumber: Yup.string()
      .matches(/^(\d{10})$/, "Phone number is not valid")
      .required("Phone Number is Required"),
  });
  //FORMIK INTIALIZATION FOR NAME
  const formikName = useFormik({
    initialValues: {
      name: user?.name,
    },
    validationSchema: ChangeName,
    onSubmit: (values) => {
      handleSubmit(values, "name");
    },
  });
  //FORMIK INTIALIZATION FOR EMAIL
  const formikEmail = useFormik({
    initialValues: {
      email: user?.email,
    },
    validationSchema: ChangeEmail,
    onSubmit: (values) => {
      handleSubmit(values, "email");
    },
  });
  //FORMIK INTIALIZATION FOR PASSWORD
  const formikPassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ChangePassword,
    onSubmit: (values) => {
      handleSubmit(values, "password");
    },
  });
  //FORMIK INTIALIZATION FOR PHONE NO.
  const formikPhoneNo = useFormik({
    initialValues: {
      phoneNumber: user?.phoneNumber?.substring(2),
    },
    validationSchema: ChangePhoneNo,
    onSubmit: (values) => {
      handleSubmit(values, "phoneNumber");
    },
  });
  return (
    <section className="text-body-heading body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        {!user ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading />
          </div>
        ) : (
          <div className="-my-8 divide-y-2 divide-body-heading">
            <CustomModal
              open={nameModal}
              handleClose={() => handleClose("name")}
            >
              <form
                onSubmit={formikName.handleSubmit}
                className="w-full max-w-2xl p-4"
              >
                <div className="text-xl flex w-full items-center mt-2 justify-center text-black-shade ">
                  Change Name
                </div>
                <div className="relative z-0 w-full my-5 group">
                  <input
                    type="text"
                    name="name"
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
                    placeholder=""
                    value={formikName.values.name}
                    onChange={formikName.handleChange}
                    onBlur={formikName.handleBlur}
                    required
                  />
                  <label
                    htmlFor="name"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Enter New Name
                  </label>
                </div>
                {formikName.touched.name && formikName.errors.name && (
                  <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                    {formikName.errors.name}
                  </div>
                )}
                <div className="w-full flex justify-end mb-3">
                  <button
                    disabled={isLoading ? true : false}
                    type="submit"
                    className="text-white-shade disabled:bg-body-heading-hover font-semibold bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base "
                  >
                    {isLoading ? "Updating" : "Submit"}
                  </button>
                </div>
              </form>
            </CustomModal>
            <CustomModal
              open={emailModal}
              handleClose={() => handleClose("email")}
            >
              <form
                onSubmit={formikEmail.handleSubmit}
                className="w-full max-w-2xl p-4"
              >
                <div className="text-xl flex w-full items-center mt-2 justify-center text-black-shade ">
                  Change Email
                </div>
                <div className="relative z-0 w-full my-5 group">
                  <input
                    type="email"
                    name="email"
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
                    placeholder=""
                    value={formikEmail.values.email}
                    onChange={formikEmail.handleChange}
                    onBlur={formikEmail.handleBlur}
                    required
                  />
                  <label
                    htmlFor="name"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Enter New Email
                  </label>
                </div>
                {formikEmail.touched.email && formikEmail.errors.email && (
                  <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                    {formikEmail.errors.email}
                  </div>
                )}
                <div className="w-full flex justify-end mb-3">
                  <button
                    disabled={isLoading ? true : false}
                    type="submit"
                    className="text-white-shade disabled:bg-body-heading-hover font-semibold bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base "
                  >
                    {isLoading ? "Updating" : "Submit"}
                  </button>
                </div>
              </form>
            </CustomModal>
            <CustomModal
              open={passwordModal}
              handleClose={() => handleClose("password")}
            >
              <form
                onSubmit={formikPassword.handleSubmit}
                className="w-full max-w-2xl p-4"
              >
                <div className="text-xl flex w-full items-center mt-2 justify-center text-black-shade ">
                  Change Password
                </div>
                <div className="relative z-0 w-full my-5 group">
                  <input
                    type="text"
                    name="oldPassword"
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
                    placeholder=""
                    value={formikPassword.values.oldPassword}
                    onChange={formikPassword.handleChange}
                    onBlur={formikPassword.handleBlur}
                    required
                  />
                  <label
                    htmlFor="name"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Enter Old Password
                  </label>
                </div>
                {formikPassword.touched.oldPassword &&
                  formikPassword.errors.oldPassword && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formikPassword.errors.oldPassword}
                    </div>
                  )}
                <div className="relative z-0 w-full my-5 group">
                  <input
                    type="text"
                    name="newPassword"
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
                    placeholder=""
                    value={formikPassword.values.newPassword}
                    onChange={formikPassword.handleChange}
                    onBlur={formikPassword.handleBlur}
                    required
                  />
                  <label
                    htmlFor="name"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Enter New Password
                  </label>
                </div>
                {formikPassword.touched.newPassword &&
                  formikPassword.errors.newPassword && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formikPassword.errors.newPassword}
                    </div>
                  )}
                <div className="relative z-0 w-full my-5 group">
                  <input
                    type="text"
                    name="confirmPassword"
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
                    placeholder=""
                    value={formikPassword.values.confirmPassword}
                    onChange={formikPassword.handleChange}
                    onBlur={formikPassword.handleBlur}
                    required
                  />
                  <label
                    htmlFor="name"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Enter Confirm Password
                  </label>
                </div>
                {formikPassword.touched.confirmPassword &&
                  formikPassword.errors.confirmPassword && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formikPassword.errors.confirmPassword}
                    </div>
                  )}
                <div className="w-full flex justify-end mb-3">
                  <button
                    disabled={isLoading ? true : false}
                    type="submit"
                    className="text-white-shade disabled:bg-body-heading-hover font-semibold bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base  "
                  >
                    {isLoading ? "Updating" : "Submit"}
                  </button>
                </div>
              </form>
            </CustomModal>
            <CustomModal
              open={phoneNoModal}
              handleClose={() => handleClose("phoneNumber")}
            >
              <form
                onSubmit={formikPhoneNo.handleSubmit}
                className="w-full max-w-2xl p-4"
              >
                <div className="text-xl flex w-full items-center mt-2 justify-center text-black-shade ">
                  Update Phone Number
                </div>
                <div className="flex my-6">
                  <span className="inline-flex items-center px-3 text-sm text-black-shade bg-gray-100 border rounded-e-0 border-black-shade border-e-0 rounded-s-md ">
                    <p>+1</p>
                  </span>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formikPhoneNo.values.phoneNumber}
                    onChange={formikPhoneNo.handleChange}
                    onBlur={formikPhoneNo.handleBlur}
                    className="rounded-none rounded-e-lg bg-transparent border focus:outline-none text-black-shade block flex-1 min-w-0 w-full text-sm border-black-shade p-2.5  "
                    placeholder="Enter Phone No."
                    maxLength={10}
                  />
                </div>
                {formikPhoneNo.touched.phoneNumber &&
                  formikPhoneNo.errors.phoneNumber && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formikPhoneNo.errors.phoneNumber}
                    </div>
                  )}
                <div className="w-full flex text-sm justify-end mb-3">
                  <button
                    disabled={isLoading ? true : false}
                    type="submit"
                    className="text-white-shade disabled:bg-body-heading-hover font-semibold bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base "
                  >
                    {isLoading ? "Updating" : "Submit"}
                  </button>
                </div>
              </form>
            </CustomModal>
            <div className="py-8 flex flex-wrap items-center justify-center w-full md:flex-nowrap">
              <div className="md:flex-grow">
                <h2 className="text-2xl font-medium text-black-shade title-font mb-5">
                  Change Name
                </h2>
                <div className="flex gap-2 items-center">
                  <input
                    type="text"
                    className=" max-w-96 bg-gray-100 border border-body-heading text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    value={user.name}
                    disabled
                  />
                  <button
                    onClick={() => handleOpen("name")}
                    className="text-shop-head bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base"
                  >
                    Change Name
                  </button>
                </div>
              </div>
            </div>
            <div className="py-8 flex flex-wrap items-center justify-center w-full md:flex-nowrap">
              <div className="md:flex-grow">
                <h2 className="text-2xl font-medium text-black-shade title-font mb-5">
                  Change Email
                </h2>
                <div className="flex gap-2 items-center">
                  <input
                    type="text"
                    className=" max-w-96 bg-gray-100 border border-body-heading text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    value={user.email}
                    disabled
                  />
                  <button
                    onClick={() => handleOpen("email")}
                    className="text-shop-head bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base"
                  >
                    Change Email
                  </button>
                </div>
              </div>
            </div>
            <div className="py-8 flex flex-wrap items-center justify-center w-full md:flex-nowrap">
              <div className="md:flex-grow">
                <h2 className="text-2xl font-medium text-black-shade title-font mb-5">
                  Change Password
                </h2>
                <div className="flex gap-2 items-center">
                  <input
                    type="text"
                    className=" max-w-96 bg-gray-100 border border-body-heading text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    value="########"
                    disabled
                  />
                  <button
                    onClick={() => handleOpen("password")}
                    className="text-shop-head bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
            <div className="py-8 flex flex-wrap items-center justify-center w-full md:flex-nowrap">
              <div className="md:flex-grow">
                <h2 className="text-2xl font-medium text-black-shade title-font mb-5">
                  {user?.phoneNumber ? "Change Phone No." : "Add Phone Number"}
                </h2>
                <div className="flex gap-2 items-center">
                  <input
                    type="text"
                    className=" max-w-96 bg-gray-100 border border-body-heading text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    value={user?.phoneNumber ? user.phoneNumber : "Add Phone"}
                    disabled
                  />
                  <button
                    onClick={() => handleOpen("phoneNumber")}
                    className="text-shop-head bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base"
                  >
                    {user?.phoneNumber
                      ? "Change Phone No."
                      : "Add Phone Number"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProfileSettings;
