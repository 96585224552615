import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";

import {
  Login,
  Register,
  AdminLogin,
  ForgotPassword,
  ResetPassword,
} from "./pages";
import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";
import Home from "./Home";
import Shop from "./pages/Shop";
import { useAuthContext } from "./contexts/AuthContext";
import logo from "./data/logo.png";
import Loading from "./components/Loading";

const App = () => {
  const { user, isLoggedIn, isAdmin, isFrontDesk } = useAuthContext();
  const { currentMode } = useStateContext();

  if (isLoggedIn === null) {
    return (
      <div className="h-screen bg-black-shade w-screen flex items-center justify-center">
        <div>
          <img
            className="mx-auto h-full w-full"
            src={logo}
            alt="Longevity Lounge"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/login"
              element={isLoggedIn ? <Navigate to={"/"} replace /> : <Login />}
            />
            <Route
              exact
              path="/register"
              element={
                isLoggedIn ? <Navigate to={"/"} replace /> : <Register />
              }
            />
            <Route
              exact
              path="/forgot-password"
              element={
                isLoggedIn ? <Navigate to={"/"} replace /> : <ForgotPassword />
              }
            />
            <Route
              exact
              path="/reset-password/:token"
              element={
                isLoggedIn ? <Navigate to={"/"} replace /> : <ResetPassword />
              }
            />
            <Route exact path="/*" element={<Shop />} />
            <Route
              exact
              path="/admin/*"
              element={isAdmin || isFrontDesk ? <Home /> : <Navigate to={"/"} replace />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
};

export default App;
