import React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  StackingColumnSeries,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

import {
  stackedCustomSeries,
  stackedPrimaryXAxis,
  stackedPrimaryYAxis,
} from "../../data/dummy";
import { useStateContext } from "../../contexts/ContextProvider";
import { useAdminContext } from "../../contexts/AdminContext";

const Stacked = ({ width, height }) => {
  const { monthlyRevenue } = useAdminContext();
  const data = [
    { x: "Jan", y: 111.1 },
    { x: "Feb", y: 127.3 },
    { x: "Mar", y: 143.4 },
    { x: "Apr", y: 159.9 },
    { x: "May", y: 159.9 },
    { x: "Jun", y: 159.9 },
    { x: "July", y: 159.9 },
  ];

  let objData = data.map((item) => {
    monthlyRevenue?.map((revenue) => {
      if (revenue.month === item.x) {
        item.y = revenue.revenue;
      }
    });
    return item;
  });

  const dataSource = [
    {
      dataSource: objData,
      xName: "x",
      yName: "y",
      name: "Sales by month",
      type: "StackingColumn",
      background: "white",
    },
  ];
  const { currentMode } = useStateContext();
  return (
    <ChartComponent
      id="charts"
      primaryXAxis={stackedPrimaryXAxis}
      primaryYAxis={stackedPrimaryYAxis}
      width={width}
      height={height}
      chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true }}
      // background={currentMode === }
      legendSettings={{ background: "white" }}
    >
      <Inject services={[Legend, Category, StackingColumnSeries, Tooltip]} />
      <SeriesCollectionDirective>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {dataSource.map((item, index) => (
          <SeriesDirective columnWidth={0.8} key={index} {...item} />
        ))}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default Stacked;
