import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Tooltip,
  IconButton,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import { Delete, Edit, AccountCircle, Send } from "@mui/icons-material";
import { useStateContext } from "../contexts/ContextProvider";
import Loading from "./Loading";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const Table = ({
  columns,
  data,
  renderRowActions,
  renderRowActionMenuItems,
}) => {
  const { currentMode } = useStateContext();

  const theme = {
    light: "#fafafa",
    lightText: "#0e0e0e",
    dark: "#20232A",
    darkText: "#fafafa",
  };

  const customDatePickerFormat = {
    normalDate: "YYYY-M-DD",
    normalDateTime: "YYYY-M-DD HH:mm:ss",
    normalTime: "HH:mm:ss",
  }

  const table = useMaterialReactTable({
    data,
    columns,
    // filterFns: {
    //   customFilterFn: (row, id, filterValue) => {
    //     // render selected date in the format of "YYYY-M-DD"
    //     console.log(filterValue, "filterValue")
    //     console.log(row[id], "row")
    //     console.log(id, "id")
    //     const selectedDate = new Date(filterValue);
    //     const rowDate = new Date(row[id]);
    //     return (
    //       selectedDate.getFullYear() === rowDate.getFullYear() &&
    //       selectedDate.getMonth() === rowDate.getMonth() &&
    //       selectedDate.getDate() === rowDate.getDate()
    //     );

    //   }
    // },
    muiTopToolbarProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color:
          currentMode === "Dark"
            ? `${theme.darkText} !important`
            : `${theme.lightText} !important`,
        "& .MuiButtonBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
        "& .MuiInputBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
        "& .MuiInputBase-root fieldset": {
          border:
            currentMode === "Dark"
              ? `1px solid ${theme.darkText} !important`
              : `1px solid ${theme.lightText} !important`,
        },
      },
    },
    muiBottomToolbarProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
        "& .MuiTablePagination-root svg": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
        "& .MuiFormLabel-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
        "& .MuiInputBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
        "& .Mui-disabled svg": {
          color:
            currentMode === "Dark"
              ? `#999999 !important`
              : `#737373 !important`,
        },
      },
    },
    muiTableBodyProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
      },
    },
    muiTableProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
      },
    },
    muiTableHeadRowProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
      },
    },
    muiTableBodyRowProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
        "& .MuiTableSortLabel-root svg": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
        "& .MuiButtonBase-root svg": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
      },
    },
    muiColumnDragHandleProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
      },
    },
    muiFilterTextFieldProps: {
      sx: {
        "& .MuiInputBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
          "&:before": {
            borderBottom:
              currentMode === "Dark"
                ? `1px solid ${theme.darkText} !important`
                : `1px solid ${theme.lightText} !important`,
          },
          "&:after": {
            borderBottom:
              currentMode === "Dark"
                ? `2px solid ${theme.darkText} !important`
                : `2px solid ${theme.lightText} !important`,
          },
        },
        "& .MuiButtonBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
      },
    },
    muiFilterDatePickerProps: {
      sx: {
        "& .MuiInputBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
          "&:before": {
            borderBottom:
              currentMode === "Dark"
                ? `1px solid ${theme.darkText} !important`
                : `1px solid ${theme.lightText} !important`,
          },
          "&:after": {
            borderBottom:
              currentMode === "Dark"
                ? `2px solid ${theme.darkText} !important`
                : `2px solid ${theme.lightText} !important`,
          },
        },
        "& .MuiButtonBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
      },
    },
    muiFilterDateTimePickerProps: {
      sx: {
        "& .MuiInputBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
          "&:before": {
            borderBottom:
              currentMode === "Dark"
                ? `1px solid ${theme.darkText} !important`
                : `1px solid ${theme.lightText} !important`,
          },
          "&:after": {
            borderBottom:
              currentMode === "Dark"
                ? `2px solid ${theme.darkText} !important`
                : `2px solid ${theme.lightText} !important`,
          },
        },
        "& .MuiButtonBase-root": {
          color:
            currentMode === "Dark"
              ? `${theme.darkText} !important`
              : `${theme.lightText} !important`,
        },
      },
    },
    muiSearchTextFieldProps: {
      sx: {
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#fafafa",
          },
          "& fieldset": {
            borderColor: "#fafafa",
          },
          "&:hover fieldset": {
            borderColor: "#fafafa",
          },
          "&.MuiInputAdornment-root": {
            color: "fafafa",
          },
          color: currentMode === "Dark" ? theme.darkText : theme.lightText,
        },
      },
    },
    muiTableHeadProps: {
      sx: {
        background: currentMode === "Dark" ? theme.dark : theme.light,
        color: currentMode === "Dark" ? theme.darkText : theme.lightText,
      },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 120,
      },
    },
    editingMode: "modal",
    // enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableEditing: true,
    renderRowActions: renderRowActions,
    renderRowActionMenuItems: renderRowActionMenuItems,
  });
  return (
    <>
      {!data ? (
        <Loading />
      ) : (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="zh-cn" >
          <MaterialReactTable table={table} />
        </LocalizationProvider>
      )}
    </>
  );
};

export default Table;
