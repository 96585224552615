import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthContext";

const INITIAL_STATES = {
  products: null,
  rooms: null,
  userAppointments: null,
  userServices: null,
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
};

export const FetchContext = createContext(INITIAL_STATES);

export const FetchContextProvider = ({ children }) => {
  const [dataState, setDataState] = useState(INITIAL_STATES);
  const { user, token, isAdmin, isFrontDesk } = useAuthContext();
  const baseUrl =
    process.env.REACT_APP_DEVELOPMENT_MODE === "DEVELOPMENT"
      ? process.env.REACT_APP_TESTING_REACT_APP_URL
      : process.env.REACT_APP_URL;

  const fetchProducts = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/shop/products`);
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, products: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchRooms = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/all-rooms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, rooms: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchSingleProduct = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/shop/products/${id}`);
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchAvailableSlots = async (formData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/shop/fetch-available-slots`,
        formData
      );
      return data.availableSlots;
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchUserAppointments = async () => {
    setDataState({ ...dataState, isLoading: true, isError: null });
    try {
      const { data } = await axios.get(`${baseUrl}/user/my-appointments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          userAppointments: data.userAppointments,
          userServices: data.userServicesWithProduct,
          isLoading: false,
        }));
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => {
    user && (isAdmin || isFrontDesk) && fetchRooms();
    user && fetchUserAppointments();
  }, [user]);

  return (
    <FetchContext.Provider
      value={{
        ...dataState,
        fetchProducts,
        fetchSingleProduct,
        fetchAvailableSlots,
        fetchUserAppointments,
        fetchRooms,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};

export const useFetchContext = () => useContext(FetchContext);
