import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../contexts/AuthContext";
import logo from "../data/logo.png";
import Loading from "../components/Loading";

const ForgotPassword = () => {
  const { forgotPassword, loading, error } = useAuthContext();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    let dataToSend = { ...values, email: values.email.toLowerCase() };
    await forgotPassword(dataToSend);
  };
  //FORM SCHEMA FOR FORMIK
  const ForgotPassword = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPassword,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className="h-screen bg-black-shade w-screen flex items-center justify-center">
      <button
        onClick={() => navigate(-1)}
        className="absolute cursor-pointer hover:bg-shop-head hover:text-body-heading duration-300 transition h-12 flex items-center justify-center rounded-full bg-transparent text-shop-head w-12 top-10 left-10"
      >
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-5 h-5 rotate-180"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </button>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-full w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="my-5 text-center text-2xl font-bold leading-9 tracking-tight text-body-heading">
            Enter email to reset password
          </h2>
        </div>
        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-10" onSubmit={formik.handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-shop-head"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="block w-full bg-shop-head px-1 rounded-md border-0 py-1.5 text-body-heading shadow-sm ring-1 ring-inset ring-body-heading placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-white-shade text-xs mt-1">
                    {formik.errors.email}
                  </div>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={loading ? true : false}
                className="flex w-full justify-center disabled:border-body-heading-hover rounded-md bg-body-heading px-3 py-1.5 text-sm font-semibold leading-6 text-shop-head shadow-sm hover:bg-body-heading-hover"
              >
                {loading ? (
                  <Loading
                    variant={"round"}
                    color={"black"}
                    bgColor={"#c78c4e"}
                    width={"1rem"}
                  />
                ) : (
                  "Send Email"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
