import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { useStateContext } from "../contexts/ContextProvider";
import { FaMinusCircle } from "react-icons/fa";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { useFetchContext } from "../contexts/FetchContext";
import { usePostContext } from "../contexts/PostContext";
import { useNavigate } from "react-router-dom";
const CreateProduct = () => {
  const { currentColor } = useStateContext();
  const { rooms } = useFetchContext();
  const { PostProductData } = usePostContext();
  const [basicServices, setBasicServices] = useState([
    {
      id: 1,
      name: "",
      price: "",
      type: "basic",
    },
  ]);
  const [multiSessionServices, setMultiSessionServices] = useState([
    {
      id: 1,
      name: "",
      price: "",
      type: "multiSession",
      duration: "",
    },
  ]);
  const [images, setImages] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  const hiddenFileInput = useRef(null);
  const hiddenFileInputSingle = useRef(null);

  const handleFileAdd = () => {
    hiddenFileInput.current.click();
  };
  const handleSingleFileAdd = () => {
    hiddenFileInputSingle.current.click();
  };

  const handleSubmit = async (values) => {
    setBtnLoading(true);
    await PostProductData(values, basicServices, multiSessionServices);
    formik.resetForm();
    setBtnLoading(false);
    navigate("admin/products");
  };

  const ProductSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    startingPrice: Yup.number().required("Required"),
    description: Yup.string()
      .min(2, "Too Short!")
      .max(300, "Too Long!")
      .required("Required"),
    room: Yup.array()
      .of(Yup.object())
      .min(1, "Select atleast 1 room")
      .required("Required"),
    images: Yup.array()
      .of(Yup.string())
      .min(4, "Upload Atleast 4 Images")
      .max(4, "Max 4 Images")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      startingPrice: "",
      description: "",
      room: "",
      services: "",
      images: "",
    },
    validationSchema: ProductSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("images", images);
  }, [images]);

  const updateBasicServiceName = (id, field, newVal) => {
    setBasicServices((prevData) => {
      const index = prevData.findIndex((item) => item.id === id);
      if (index !== -1) {
        const newData = [...prevData];
        newData[index] = { ...newData[index], [field]: newVal };
        return newData;
      }
      return prevData;
    });
  };
  const removeBasicServiceValue = (objectIdToRemove) => {
    if (basicServices.length !== 1) {
      const updatedArray = basicServices.filter(
        (obj) => obj.id !== objectIdToRemove
      );
      setBasicServices(updatedArray);
    }
  };
  const updateMultiSessionServiceName = (id, field, newVal) => {
    setMultiSessionServices((prevData) => {
      const index = prevData.findIndex((item) => item.id === id);
      if (index !== -1) {
        const newData = [...prevData];
        newData[index] = { ...newData[index], [field]: newVal };
        return newData;
      }
      return prevData;
    });
  };
  const removeMultiSessionServiceValue = (objectIdToRemove) => {
    if (multiSessionServices.length !== 1) {
      const updatedArray = multiSessionServices.filter(
        (obj) => obj.id !== objectIdToRemove
      );
      setMultiSessionServices(updatedArray);
    }
  };
  const handleFileImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const imagesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files[i];

      reader.onload = () => {
        const base64Image = reader.result;
        imagesArray.push(base64Image);
        if (imagesArray.length === files.length) {
          setImages(imagesArray);
        }
      };
      reader.readAsDataURL(file);
    }
    setImagePreview(files.map((file) => URL.createObjectURL(file)));
  };
  const handleSingleFileImageChange = (e) => {
    const file = e.target.files[0];
    if (e.target.files.length > 0) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImages((prevData) => [...prevData, reader.result.toString()]);
      };
      reader.readAsDataURL(file);
      setImagePreview([...imagePreview, URL.createObjectURL(file)]);
    }
  };
  const removeRoom = (room) => {
    setSelected(() => {
      const filteredRooms = selected.filter((i) => i !== room);
      formik.setFieldValue("room", filteredRooms);
      return filteredRooms;
    });
  };
  const addRoom = (room) => {
    setSelected((prev) => {
      const newArr = [...prev, room];
      formik.setFieldValue("room", newArr);
      return newArr;
    });
    inputRef.current.blur();
  };
  const [selected, setSelected] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  const inputRef = useRef(null);

  const filteredRooms = rooms?.filter((room) => !selected.includes(room));
  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1
          style={{ color: currentColor }}
          className="text-3xl text-center font-medium title-font mb-10 tracking-widest"
        >
          Create Product
        </h1>
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <div className="flex w-full lg:w-1/2 max-w-md mx-auto lg:min-h-max min-h-[20rem] flex-col">
            <input
              multiple
              ref={hiddenFileInput}
              className="hidden"
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              onChange={(e) => handleFileImagesChange(e)}
            />
            <input
              ref={hiddenFileInputSingle}
              className="hidden"
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              onChange={(e) => handleSingleFileImageChange(e)}
            />
            {imagePreview.length > 0 && (
              <div className="relative">
                <div className="absolute h-full w-full">
                  <div
                    onClick={() => {
                      const newImages = imagePreview.slice(1); // Remove the first image
                      setImages(newImages);
                      setImagePreview(newImages);
                    }}
                    className="h-8 w-8 mt-1 ml-1 transition duration-300 hover:bg-black-shade dark:hover:bg-white-shade rounded-full cursor-pointer hover:text-white-shade dark:hover:text-black-shade flex items-center justify-center"
                  >
                    <FaMinusCircle className="h-5 w-5" />
                  </div>
                </div>
                <img
                  src={imagePreview[0]}
                  alt="product"
                  className="w-full h-full object-cover rounded-md"
                />
              </div>
            )}
            <div
              onClick={handleFileAdd}
              className={` h-full max-h-[30rem] border cursor-pointer max-w-md mx-auto w-full hover:ring-2 dark:hover:ring-white hover:ring-black dark:border-white-shade border-black-shade text-black-shade dark:text-white-shade rounded-md flex flex-col items-center justify-center ${
                imagePreview.length > 0 ? "hidden" : ""
              }`}
            >
              <AiOutlinePlusCircle className="w-20 h-20" />
              <p className="text-xl ">Add Images</p>
            </div>

            {/* Render Image Preview Here */}
            <div className="grid grid-cols-3 h-full lg:h-1/5 w-full mt-4">
              {imagePreview.slice(1).map((img, i) => (
                <div key={i} className=" p-2">
                  <div className="relative h-full">
                    <div className="absolute h-full w-full">
                      <div
                        onClick={() => {
                          const newImages = imagePreview.filter(
                            (img, index) => index !== i + 1
                          );
                          setImages(newImages);
                          setImagePreview(
                            newImages.map((file) => {
                              return file;
                            })
                          );
                        }}
                        className="h-6 w-6 mt-1 ml-1 transition duration-300 hover:bg-black-shade dark:hover:bg-white-shade rounded-full cursor-pointer hover:text-white-shade dark:hover:text-black-shade flex items-center justify-center"
                      >
                        <FaMinusCircle className="h-4 w-4" />
                      </div>
                    </div>
                    <img
                      src={img}
                      alt="product"
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                </div>
              ))}
              {imagePreview.length > 0 && imagePreview.length < 4 && (
                <div className="p-2">
                  <div
                    onClick={handleSingleFileAdd}
                    className=" p-2 h-full border cursor-pointer hover:ring-2 dark:hover:ring-white hover:ring-black dark:border-white-shade border-black-shade text-black-shade dark:text-white-shade rounded-md flex flex-col items-center justify-center"
                  >
                    <AiOutlinePlusCircle className="w-10 h-10" />
                    <p className="text-base ">Add More</p>
                  </div>
                </div>
              )}
            </div>
            {formik.touched.images && formik.errors.images && (
              <div className="text-slate-100 ml-1">{formik.errors.images}</div>
            )}
          </div>

          <div className="lg:w-1/2 mt-4 lg:mt-0 w-full lg:pl-10 lg:py-6 mb-6 lg:mb-0">
            <form onSubmit={formik.handleSubmit} className="max-w-md mx-auto">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="name"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Product Name
                </label>
                {formik.touched.name && formik.errors.name && (
                  <div className="text-white-shade text-xs mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="number"
                  name="startingPrice"
                  value={formik.values.startingPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="startingPrice"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Starting Price
                </label>
                {formik.touched.startingPrice &&
                  formik.errors.startingPrice && (
                    <div className="text-white-shade text-xs mt-1">
                      {formik.errors.startingPrice}
                    </div>
                  )}
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-black-shade dark:text-white-shade"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="4"
                  className="block p-2.5 w-full text-sm text-black-shade bg-gray-50 rounded-lg border border-black-shade dark:bg-main-dark-bg dark:border-white-shade dark:placeholder-gray-300 placeholder-gray-700 dark:text-white-shade "
                  placeholder="Add Description"
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className="text-white-shade text-xs mt-1">
                    {formik.errors.description}
                  </div>
                )}
              </div>
              <div className="relative max-h-96 z-0 flex flex-col w-full text-sm mb-5 group ">
                <div className="border-black-shade w-full dark:border-white-shade dark:text-white-shade text-black-shade rounded-md border gap-2 relative text-xs flex flex-col flex-wrap p-2 mb-2 ">
                  <div className="text-base">Rooms</div>
                  <div className="flex flex-wrap gap-1 ">
                    {selected.length ? (
                      selected?.map((room) => (
                        <div
                          key={room._id}
                          className="rounded-full w-fit  py-1.5 pl-3 pr-1 border border-black-shade dark:border-white-shade bg-white dark:bg-secondary-dark-bg flex items-center gap-2"
                        >
                          {room.name}
                          <div
                            className="cursor-pointer"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => removeRoom(room)}
                          >
                            <IoIosClose className="h-4 w-4" />
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Rooms Selected</p>
                    )}
                  </div>
                </div>
                <div
                  className={`card border-black-shade w-full dark:border-white-shade dark:text-white-shade text-black-shade border flex items-center justify-between p-3 gap-2.5 ${
                    menuOpen ? "rounded-t-md border-b-0" : "rounded-md"
                  } `}
                >
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Select Rooms"
                    name="room"
                    className="bg-transparent outline-none text-sm flex-1 "
                    onFocus={() => setMenuOpen(true)}
                    onBlur={() => setMenuOpen(false)}
                  />
                </div>
                {menuOpen && (
                  <div className="border-black-shade dark:border-white-shade dark:text-white-shade text-black-shade rounded-b-md border w-full p-1 flex overflow-y-auto scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-slate-200">
                    <ul className="w-full">
                      {filteredRooms?.length ? (
                        filteredRooms.map((room) => (
                          <li
                            key={room._id}
                            className="p-2 cursor-pointer hover:bg-white dark:hover:bg-secondary-dark-bg hover:text-black-shade dark:hover:text-white-shade rounded-md w-full"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => addRoom(room)}
                          >
                            {room.name}
                          </li>
                        ))
                      ) : (
                        <li className="p-2 text-gray-500">
                          No options available
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {formik.touched.room && formik.errors.room && (
                  <div className="text-white-shade text-xs mt-1">
                    {formik.errors.room}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <h2 className="text-lg mb-2">Basic Services</h2>
                {basicServices.map((ele, i) => (
                  <div key={i} className="grid grid-cols-7 gap-2 md:gap-6">
                    <div className="relative col-span-3 z-0 w-full mb-5 group">
                      <input
                        type="text"
                        name="name"
                        value={ele.name}
                        onChange={(e) =>
                          updateBasicServiceName(
                            ele.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Name
                      </label>
                    </div>
                    <div className="relative col-span-3 z-0 w-full mb-5 group">
                      <input
                        type="number"
                        name="price"
                        onChange={(e) =>
                          updateBasicServiceName(
                            ele.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        value={ele.price}
                        className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="price"
                        className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Price
                      </label>
                    </div>
                    <div className="relative z-0 flex items-center justify-center group">
                      <div
                        onClick={() => removeBasicServiceValue(ele.id)}
                        className="h-8 w-8 transition duration-300 hover:bg-black-shade dark:hover:bg-white-shade rounded-full cursor-pointer hover:text-white-shade dark:hover:text-black-shade flex items-center justify-center "
                      >
                        <FaMinusCircle className="h-5 w-5" />
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  onClick={() =>
                    setBasicServices((prevData) => {
                      return [
                        ...basicServices,
                        {
                          id: basicServices[prevData.length - 1].id + 1,
                          name: "",
                          price: "",
                          type: "basic",
                        },
                      ];
                    })
                  }
                  className="w-full transiton duration-300 my-2 hover:bg-black-shade hover:text-white-shade dark:hover:bg-white-shade dark:hover:text-black-shade cursor-pointer border border-black-shade dark:border-white-shade rounded-md p-2 inline-flex items-center justify-center "
                >
                  <p className="text-sm">Add More</p>
                  <AiOutlinePlusCircle className="ml-1 h-5 w-5" />
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <h2 className="text-lg mb-2">Multi Session Services</h2>
                {multiSessionServices.map((ele, i) => (
                  <div key={i} className="grid grid-cols-7 gap-2 md:gap-6">
                    <div className="col-span-7">
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="text"
                          name="name"
                          onChange={(e) =>
                            updateMultiSessionServiceName(
                              ele.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                          value={ele.name}
                          className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="name"
                          className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Name
                        </label>
                      </div>
                    </div>
                    <div className="relative col-span-3 z-0 w-full mb-5 group">
                      <input
                        type="number"
                        name="price"
                        onChange={(e) =>
                          updateMultiSessionServiceName(
                            ele.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        value={ele.price}
                        className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="price"
                        className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Price
                      </label>
                    </div>
                    <div className="relative col-span-3 z-0 w-full mb-5 group">
                      <input
                        type="number"
                        name="duration"
                        onChange={(e) =>
                          updateMultiSessionServiceName(
                            ele.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        value={ele.duration}
                        className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="duration"
                        className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Sessions
                      </label>
                    </div>
                    <div className="relative z-0 flex items-center justify-center group">
                      <div
                        onClick={() => removeMultiSessionServiceValue(ele.id)}
                        className="h-8 w-8 transition duration-300 hover:bg-black-shade dark:hover:bg-white-shade rounded-full cursor-pointer hover:text-white-shade dark:hover:text-black-shade flex items-center justify-center "
                      >
                        <FaMinusCircle className="h-5 w-5" />
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  onClick={() =>
                    setMultiSessionServices((prevData) => {
                      return [
                        ...multiSessionServices,
                        {
                          id: multiSessionServices[prevData.length - 1].id + 1,
                          name: "",
                          price: "",
                          type: "multiSession",
                          duration: 0,
                        },
                      ];
                    })
                  }
                  className="w-full transiton duration-300 my-2 hover:bg-black-shade hover:text-white-shade dark:hover:bg-white-shade dark:hover:text-black-shade cursor-pointer border border-black-shade dark:border-white-shade rounded-md p-2 inline-flex items-center justify-center"
                >
                  <p className="text-sm">Add More</p>
                  <AiOutlinePlusCircle className="ml-1 h-5 w-5" />
                </div>
              </div>
              <div className=" w-full my-2 flex justify-end ">
                <button
                  type="submit"
                  style={{ backgroundColor: currentColor }}
                  disabled={btnLoading}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  {btnLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateProduct;
