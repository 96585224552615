import photo from "../data/logo.png";
import { Link } from "react-router-dom";
import moment from "moment";

const ActiveService = ({
  productImage,
  serviceCount,
  productName,
  serviceName,
  serviceId,
  productId,
  userServiceId,
}) => {
  return (
    <div className="p-4 md:w-1/3">
      <div className="h-full border-2 border-black-shade rounded-lg overflow-hidden">
        <div className="lg:h-48 md:h-36 bg-body-heading ">
          <img
            className=" w-full max-h-44 md:max-h-full  object-contain "
            src={productImage || photo}
          />
        </div>
        <div className="p-6 text-body-heading max-h-52 lg:max-h-44 h-full flex flex-col justify-between bg-black-shade ">
          {productName === "Unavailable" ? (
            <div className="flex items-center justify-center">UNAVAILABLE</div>
          ) : (
            <>
              <div>
                <h1 className="title-font line-clamp-2 text-xl font-medium mb-1">
                  {productName}
                </h1>
                <p className="leading-relaxed line-clamp-2 text-white-shade text-sm mb-4">
                  {serviceName}
                </p>
              </div>
              <div className="flex flex-col items-center flex-nowrap  md:flex-row ">
                <div className="inline-flex items-center mb-2 md:mb-0 lg:mr-auto md:mr-0 mr-auto leading-none text-sm pr-3 py-1 ">
                  <span className="mr-3 inline-flex items-center leading-none text-sm pr-3 py-1 border-r-2 border-body-heading">
                    Service Left
                  </span>
                  <span className=" inline-flex items-center leading-none text-sm">
                    {serviceCount}
                  </span>
                </div>
                {serviceCount > 0 ? (
                  <Link
                    to={`appoint/${productId}?sid=${serviceId}&usid=${userServiceId}`}
                    className="cursor-pointer hover:bg-body-heading rounded-md p-2 transition duration-300 hover:text-white-shade inline-flex items-center md:mb-2 lg:mb-0"
                  >
                    Appoint
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                ) : (
                  <Link
                    to={`/product/${productId}`}
                    className="cursor-pointer hover:bg-body-heading rounded-md p-2 transition duration-300 hover:text-white-shade inline-flex items-center md:mb-2 lg:mb-0"
                  >
                    Buy More
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveService;
