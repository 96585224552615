import React from "react";
import { Link } from "react-router-dom";

const Products = ({ link, image, name, description }) => {
  return (
    <div className="p-4 w-full max-h-[34rem] min-h-[34rem] md:w-1/3 sm:mb-0 mb-6">
      <div className="rounded-lg w-full h-full overflow-hidden">
        <Link
          className="product-image-gradient h-full w-full relative"
          to={`product/${link}`}
        >
          <div className="h-full w-full">
            <img
              alt="content"
              className="object-cover object-top h-full w-full"
              src={image}
            />
          </div>
          {/* <div className="absolute product-gradient min-h-[20rem] flex items-center justify-center translate-y-[10rem] text-white-shade w-full  hover:translate-y-0 transition duration-500 p-2 bottom-0"></div> */}
          <div class="relative h-full w-full">
            <div class="w-full h-full absolute bottom-[100%] flex flex-col justify-end bg-black bg-opacity-50 p-4">
              <h2 class="text-4xl text-center font-medium title-font text-white">
                {name}
              </h2>
              <p class="text-xl text-center leading-relaxed mt-2 text-white">
                {description}
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Products;
