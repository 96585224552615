import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import Loading from "../components/Loading";
import Calendar from "react-calendar";
import "../css/adminCalendar.css";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import TimeSlot from "../components/TimeSlot";
import { usePostContext } from "../contexts/PostContext";
import { useAdminContext } from "../contexts/AdminContext";
import { Link, useNavigate } from "react-router-dom";
const DisableSlots = () => {
  const { currentColor, currentMode } = useStateContext();
  const { EnableSlots, DisableSlots } = usePostContext();
  const { fetchAvailableSlots, products } = useFetchContext();
  const [slotLoading, setSlotLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [slotData, setSlotData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(null);
  const { fetchDisableSlots } = useAdminContext();
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    setBtnLoading(true);
    let dataToSend = {
      disabledSlots: {
        date: values.date,
        slots: {
          startTime: values.startTime,
          endTime: values.endTime,
        },
        productId: formik.values.productId,
        roomId: formik.values.roomId,
      },
    };
    await DisableSlots(dataToSend);
    await fetchDisableSlots();
    navigate("/admin/enable-slots");
    formik.resetForm();
    setSlotData(null);
    setBtnLoading(false);
  };

  const AppointmentSchema = Yup.object({
    productId: Yup.string().required("Please Select Product"),
    roomId: Yup.string().required("Please Select Room"),
    date: Yup.string().required("Please Select Date"),
    startTime: Yup.string().required("Start Time is required"),
    endTime: Yup.string().required("End Time is required"),
  });

  const formik = useFormik({
    initialValues: {
      date: null,
      startTime: null,
      endTime: null,
      productId: null,
      roomId: null,
    },
    validationSchema: AppointmentSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      console.log(values);
    },
  });

  const handleProductChange = (value) => {
    formik.setFieldValue("productId", value);
    const productDetail = products.filter(({ _id }) => _id === value)[0];
    setProductDetails(productDetail?._id ? productDetail : null);
    formik.setFieldValue("roomId", null);
    formik.setFieldValue("date", "");
    formik.setFieldValue("startTime", "");
    formik.setFieldValue("endTime", "");
  };

  const handleRoomChange = (room) => {
    formik.setFieldValue("roomId", room);
    formik.setFieldValue("date", "");
    formik.setFieldValue("startTime", "");
    formik.setFieldValue("endTime", "");
  };

  const handleDateChange = async (date) => {
    setSlotLoading(true);
    let formattedDate = moment(date).format("YYYY-M-DD");
    let dateForData = moment(date).format("YYYY-MM-DD");
    let dataToSend = {
      productId: formik.values.productId,
      selectedDate: dateForData,
      roomId: formik.values.roomId,
    };
    let data = await fetchAvailableSlots(dataToSend);
    setSlotData(data);
    formik.setFieldValue("date", formattedDate);
    formik.setFieldValue("startTime", null);
    formik.setFieldValue("endTime", null);
    setSlotLoading(false);
  };

  const handleTimeChange = async (time) => {
    const startTime = moment(time, "hh:mma");
    const endTime = productDetails?.name?.includes("Red Light Therapy")
      ? startTime.add(30, "minutes")
      : startTime.add(50, "minutes");
    const formattedEndTime = endTime.format("hh:mma");
    formik.setFieldValue("startTime", time);
    formik.setFieldValue("endTime", formattedEndTime);
  };

  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex items-center justify-between w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font tracking-widest">
            Disable Slots
          </h1>
          <Link
            to={"/admin/enable-slots"}
            style={{ backgroundColor: currentColor }}
            className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center "
          >
            <span>View Disabled Slots</span>
          </Link>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex-col mx-auto max-w-3xl"
        >
          <div className="flex items-center gap-5">
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="product" className="sr-only">
                Select Product
              </label>
              <select
                id="product"
                value={formik.values.productId}
                onChange={(e) => handleProductChange(e.target.value)}
                onBlur={formik.handleBlur}
                className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
              >
                <option
                  className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                  value=""
                >
                  Select Product
                </option>
                {products?.map((product) => (
                  <option
                    className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                    key={product._id}
                    value={product._id}
                  >
                    {product.name}
                  </option>
                ))}
              </select>
              {formik.touched.productId && formik.errors.productId && (
                <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                  {formik.errors.productId}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="room" className="sr-only">
                Room
              </label>
              <select
                id="selectedRoom"
                value={formik.values.roomId}
                onChange={(e) => handleRoomChange(e.target.value)}
                disabled={productDetails === null ? true : false}
                onBlur={formik.handleBlur}
                className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
              >
                <option
                  className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                  value=""
                >
                  Select Room
                </option>
                {productDetails?.room?.map((room, i) => (
                  <option
                    className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                    key={room._id}
                    value={room._id}
                  >
                    {room.name}
                  </option>
                ))}
              </select>
              {formik.touched.roomId && formik.errors.roomId && (
                <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                  {formik.errors.roomId}
                </div>
              )}
            </div>
          </div>
          <div className=" min-h-max w-full">
            <Calendar
              tileClassName="p-2 sm:p-3 text-sm sm:text-base bg-gray-100"
              next2Label={null}
              prev2Label={null}
              onChange={(value, e) => {
                handleDateChange(value);
              }}
              className="min-h-max rounded-sm border flex flex-col border-black overflow-hidden"
              value={formik.values.date}
              minDate={new Date()}
              maxDate={
                formik.values.roomId === null
                  ? new Date(moment().day(-1))
                  : null
              }
            />
            {formik.touched.date && formik.errors.date && (
              <div className="mt-3 text-body-heading font-semibold">
                {formik.errors.date}
              </div>
            )}
          </div>
          <div className="mt-4 max-h-96 border-black-shade dark:border-white-shade border-2 p-4 rounded-md">
            <div className="my-2">
              <p className="text-2xl font-semibold tracking-widest">Times</p>
            </div>
            {slotLoading ? (
              <div className="h-full w-full flex items-center justify-center ">
                <Loading
                  variant={"round"}
                  color={currentMode === "Dark" ? "black" : "white"}
                  bgColor={currentMode === "Dark" ? "white" : "black"}
                  width="2rem"
                />
              </div>
            ) : slotData === null ? (
              <div className="h-full w-full flex items-center justify-center ">
                <p className="text-black-shade dark:text-white-shade font-semibold py-2 text-sm">
                  Pick date for slots
                </p>
              </div>
            ) : slotData.length <= 0 ? (
              <div className="h-full flex items-center justify-center ">
                <p className="text-black-shade dark:text-white-shade font-semibold text-sm">
                  No available slots
                </p>
              </div>
            ) : (
              <div className="h-full w-full gap-1 grid grid-cols-3">
                {slotData.map((time, i) => (
                  <TimeSlot
                    key={i}
                    onClick={() => handleTimeChange(time)}
                    value={formik.values.startTime}
                    time={time}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="w-full flex justify-end my-6">
            <button
              type="submit"
              style={{ backgroundColor: currentColor }}
              disabled={btnLoading}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              {btnLoading ? "Submitting..." : "Disable"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default DisableSlots;
