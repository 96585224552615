import moment from "moment";

const AppointmentDetails = ({
  productDetails,
  service,
  room,
  date,
  startTime,
  endTime,
  totalAmount,
}) => {
  return (
    <div className="h-full min-h-max p-6 rounded-sm transition-all duration-500 bg-black-shade border text-white-shade border-body-heading flex flex-col relative overflow-hidden">
      <div className="p-2 duration-300 transition bg-black-shade border-white-shade rounded-sm items-center justify-center flex border">
        <p className="text-xs md:text-sm lg:text-base">Appointment Details</p>
      </div>
      <div className="p-2 mt-2 duration-300 transition gap-y-1 bg-black-shade border-white-shade rounded-sm items-center justify-center flex-col flex border">
        <div className="flex w-full items-center">
          <p className="text-xs w-4/12 md:text-sm lg:text-base">Service</p>
          <p className="text-xs w-2/3 md:text-sm lg:text-base">
            {service
              ? productDetails.services.filter(({ _id }) => _id == service)[0]
                .name
              : "--"}
          </p>
        </div>
        <div className="flex w-full items-center">
          <p className="text-xs w-4/12 md:text-sm lg:text-base">Room</p>
          <p className="text-xs w-2/3 md:text-sm lg:text-base">
            {room
              ? productDetails.room.filter(({ _id }) => _id == room)[0].name
              : "--"}
          </p>
        </div>
        <div className="flex w-full items-center">
          <p className="text-xs w-4/12 md:text-sm lg:text-base">Date</p>
          <p className="text-xs w-2/3 md:text-sm lg:text-base">
            {date ? moment(date, "YYYY-MM-DD").format("L") : "--"}
          </p>
        </div>
        <div className="flex w-full items-center">
          <p className="text-xs w-4/12 md:text-sm lg:text-base">Start Time</p>
          <p className="text-xs w-2/3 md:text-sm lg:text-base">
            {startTime || "--"}
          </p>
        </div>
        <div className="flex w-full items-center">
          <p className="text-xs w-4/12 md:text-sm lg:text-base">End Time</p>
          <p className="text-xs w-2/3 md:text-sm lg:text-base">
            {endTime || "--"}
          </p>
        </div>
        <div className="flex w-full items-center">
          <p className="text-xs w-4/12 md:text-sm lg:text-base">Total Amount</p>
          <p className="text-xs w-2/3 md:text-sm lg:text-base">
            {totalAmount ? `$${totalAmount}` : "--"}
            {productDetails.discount > 0 && (
              <>
                <span className=" ml-2 mb-2 text-xs line-through text-gray-400">
                  {service
                    ? productDetails.services.filter(
                      ({ _id }) => _id == service
                    )[0].price
                    : "--"}
                  $
                </span>
                <span className=" ml-2 mb-2 text-xs text-gray-400">
                  (Flat {productDetails?.discount}% off)
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetails;
