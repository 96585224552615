import React from "react";
import { Header } from "../components";
import { useAdminContext } from "../contexts/AdminContext";
import { useMemo } from "react";
import moment from "moment-timezone";
import Table from "../components/Table";
import { useStateContext } from "../contexts/ContextProvider";
import { Box, Tooltip, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../components/Loading";
const Orders = () => {
  const { appointments } = useAdminContext();
  const { currentMode, currentColor } = useStateContext();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      accessorKey: "client.name",
      header: "Client",
      size: 80,
      // Edit: ({ cell }) => (
      //   <TextField
      //     id="ID"
      //     sx={inputCustomStyleHidden}
      //     fullWidth
      //     name="ID"
      //     disabled
      //     label="ID"
      //     value={dataToEdit.ID}
      //     variant="standard"
      //   />
      // ),
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 80,

      // Header: ({ column }) => <em>{column.columnDef.header}</em>,
    },
    {
      accessorKey: "startTime",
      header: "Start Time",
      size: 80,
    },
    {
      accessorKey: "endTime",
      header: "End Time",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
    {
      accessorKey: "price",
      header: "Amount",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
    {
      accessorKey: "product.name",
      header: "Product",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
    {
      accessorFn: (row) =>
        row.isCancelled ? (
          <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-red-900 dark:text-red-300">
            Cancelled
          </span>
        ) : (
          <span className="bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-yellow-900 dark:text-yellow-200">
            Not Cancelled
          </span>
        ),
      accessorKey: "isCancelled",
      header: "Cancel Status",
      size: 80,
    },
    {
      accessorFn: (row) =>
        row.isConfirmed ? (
          <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-green-900 dark:text-green-300">
            Confirmed
          </span>
        ) : (
          <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-red-900 dark:text-red-300">
            Not Confirmed
          </span>
        ),
      accessorKey: "isConfirmed",
      header: "Confirmation Status",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
    {
      accessorFn: (row) =>
        row.isPaid ? (
          <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-green-900 dark:text-green-300">
            Paid
          </span>
        ) : (
          <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-red-900 dark:text-red-300">
            Unpaid
          </span>
        ),
      accessorKey: "isPaid",
      header: "Payment Status",
      size: 80,
    },
    {
      accessorFn: (row) =>
        row.isCancelled ? (
          row.paymentResult.isRefunded ? (
            <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-green-900 dark:text-green-300">
              Refunded
            </span>
          ) : (
            <span className="bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-yellow-900 dark:text-yellow-200">
              Refundable
            </span>
          )
        ) : (
          <span className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-gray-700 dark:text-gray-300">
            N/A
          </span>
        ),
      accessorKey: "paymentResult.isRefunded",
      header: "Refund Status",
      size: 80,
    },
  ]);
  return (
    <div className="m-2 md:m-10 p2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <div className="flex w-full justify-between items-center">
        <Header title="Appointments" />
        <Link
          to={"/admin/walk-in-customer"}
          style={{ backgroundColor: currentColor }}
          className="py-2 dark:text-white-shade text-black-shade px-5 rounded-md mb-10"
        >
          <span className="text-sm font-bold">Walk In Customer</span>
        </Link>
      </div>
      {appointments === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <div className="w-full grid">
          <Table
            data={appointments}
            renderRowActions={({ row, table }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip arrow placement="right" title="View Details">
                  <IconButton
                    onClick={() => {
                      navigate(`/admin/appointment/${row.original._id}`);
                    }}
                  >
                    <Send className="dark:text-white-shade text-black-shade" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

export default Orders;
