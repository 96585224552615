import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminProducts from "../components/AdminProducts";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import Loading from "../components/Loading";
import { useAdminContext } from "../contexts/AdminContext";
import CustomModal from "../components/CustomModal";
import { usePostContext } from "../contexts/PostContext";
const Products = () => {
  const { currentColor, currentMode } = useStateContext();
  const { AddProductDiscount, RemoveProductDiscount } = usePostContext();
  const { products } = useAdminContext();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [discount, setDiscount] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);
  const handleDiscountSubmit = async () => {
    handleClose();
    await AddProductDiscount({ discount: discount });
  };
  const handleRemoveDiscount = async () => {
    handleDeleteClose();
    handleClose();
    await RemoveProductDiscount();
  };
  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font tracking-widest">
            Products
          </h1>
          <div className="flex gap-2 items-center justify-center">
            <button
              onClick={handleOpen}
              className="py-1 md:py-2 dark:text-black-shade text-white-shade px-3 md:px-5 rounded-md dark:bg-white-shade bg-black-shade"
            >
              <span className="text-xs md:text-sm font-bold">Discount</span>
            </button>
            <Link
              to={"/admin/create-product"}
              className="py-1 md:py-2 dark:text-black-shade text-white-shade px-3 md:px-5 rounded-md dark:bg-white-shade bg-black-shade"
            >
              <span className="text-xs md:text-sm font-bold">Add Product</span>
            </Link>
          </div>
        </div>
        {products === null ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading
              color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
              bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
            />
          </div>
        ) : (
          <div className="flex flex-wrap flex-col lg:flex-row -m-4">
            {products?.map((product) => (
              <AdminProducts
                key={product._id}
                name={product.name}
                link={product._id}
                description={product.description}
                image={product.images[0].url}
              />
            ))}
            <Link
              to={"/admin/create-product"}
              style={{ color: currentColor }}
              className="p-4 lg:w-1/2"
            >
              <div className="h-full min-h-[10rem] border cursor-pointer hover:ring-2 dark:hover:ring-white hover:ring-black dark:border-white-shade border-black-shade rounded-md flex flex-col items-center justify-center ">
                <AiOutlinePlusCircle className="w-20 h-20" />
                <p className="text-xl ">Add Product</p>
              </div>
            </Link>
          </div>
        )}
      </div>
      <CustomModal open={open} handleClose={handleClose}>
        <div className="w-full flex flex-col">
          <div className="py-4 font-semibold">Add discount to all products</div>
          <div className="relative z-0 w-full mb-10 group">
            <input
              name="discount"
              type="number"
              className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
              placeholder=""
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              required
            />
            <label
              htmlFor="discount"
              className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Discount
            </label>
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={handleDeleteOpen}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade font-semibold disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
            >
              Remove Discount
            </button>
            <div className="font-semibold">
              <button
                onClick={handleClose}
                style={{ backgroundColor: currentColor }}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
              >
                No
              </button>
              <button
                onClick={handleDiscountSubmit}
                style={{ backgroundColor: currentColor }}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal open={deleteOpen} handleClose={handleDeleteClose}>
        <div className="w-full flex flex-col">
          <div className="py-4 font-semibold">
            Are You Sure Want To Remove Discounts ?
          </div>
          <div className="flex items-center n">
            <div className=" ml-auto font-semibold">
              <button
                onClick={handleDeleteClose}
                style={{ backgroundColor: currentColor }}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
              >
                No
              </button>
              <button
                onClick={handleRemoveDiscount}
                style={{ backgroundColor: currentColor }}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </section>
  );
};

export default Products;
