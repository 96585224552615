import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";

const Footer = () => {
  const { isAdmin, isFrontDesk } = useAuthContext();

  const getDashboardButton = () => {
    if (isAdmin || isFrontDesk) {
      return (
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <Link
            to="/admin"
            className="inline-flex items-center bg-btn-color border-0 py-1 px-3 focus:outline-none hover:bg-body-heading-hover rounded text-base mt-4 md:mt-0"
          >
            Dashboard
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-1"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </span>
      );
    }
  };
  return (
    <footer className="text-shop-foot bg-shop-footer-bg body-font">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <p className="text-sm text-shop-foot sm:pl-4 sm:border-l-2 sm:border-body-heading sm:py-2 sm:mt-0 mt-4">
          © 2024 Longevity Lounge —
          <a
            href="mailto:admin@longevitylounge.ca"
            className="text-body-heading ml-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            @longevitylounge
          </a>
        </p>
        {getDashboardButton()}
      </div>
    </footer>
  );
};

export default Footer;
