import moment from "moment";
import { Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
const TotalOrder = ({
  productName,
  description,
  availability,
  status,
  cancellation,
  room,
  date,
  startTime,
  endTime,
  price,
  appointmentId,
}) => {
  const { currentColor } = useStateContext();
  return (
    <Link
      to={`/admin/appointment/${appointmentId}`}
      className="py-8 flex flex-col h-full shadow-sm md:max-h-96 md:flex-row w-full"
    >
      <div className="md:ml-3 rounded-md bg-white dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-2 m-0 w-full">
        <div className="flex text-xs md:text-sm flex-col">
          <h2
            style={{ color: currentColor }}
            className="text-xl md:text-2xl font-medium title-font my-2"
          >
            {productName}
          </h2>
          <h3 className="text-sm md:text-base font-medium max-w-96 line-clamp-2 title-font mb-2">
            {description}
          </h3>
          <div className="grid grid-cols-2 ">
            <div>Room</div>
            <div className="">{room}</div>
          </div>
          {
            cancellation ? (
              <div className="grid grid-cols-2 ">
                <div>Status</div>
                <div className="text-red-500">Cancelled</div>
              </div>
            ) : (
              <div className="grid grid-cols-2 ">
                <div>Status</div>
                <div className="text-green-500">Confirmed</div>
              </div>
            )
          }
          <div className="grid grid-cols-2 ">
            <div>Date</div>
            <div className="">{date}</div>
          </div>
          <div className="grid grid-cols-2 ">
            <div>Start Time</div>
            <div className="">{startTime}</div>
          </div>
          <div className="grid grid-cols-2 ">
            <div>End Time</div>
            <div className="">{endTime}</div>
          </div>
          <div className="grid grid-cols-2 ">
            <div>Price</div>
            <div className="">{price} $</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TotalOrder;
