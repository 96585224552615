import React, { createContext, useState, useContext, useEffect } from "react";

const INITIAL_STATES = {
  item: [],
  isLoading: null,
  isError: null,
  totalPrice: null,
  inclusiveTax: null,
  taxAmount: null,
  appliedCoupon: null,
};

// Create a context for the cart
const CartContext = createContext(INITIAL_STATES);

// CartProvider component to manage cart state
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(INITIAL_STATES);

  // Function to add an item to the cart
  const addItemToCart = (item) => {
    setCart({ ...cart, item: [...cart.item, item] });
  };

  // Function to remove an item from the cart
  const removeItemFromCart = (cartId) => {
    const updatedCart = cart.item.filter(({ id }) => cartId !== id);
    setCart({ ...cart, item: updatedCart });
  };

  // Function to clear all items from the cart
  const clearCart = () => {
    setCart({ ...cart, item: [], appliedCoupon: null });
  };

  // Calculate total number of items in the cart
  const getTotalItems = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = () => {
    if (cart.item.length > 0) {
      let totalPrice = cart.item.reduce((accumulator, appointment) => {
        return (
          accumulator + (JSON.parse(appointment.appointmentDetails.price) || 0)
        );
      }, 0);
      setCart((prevData) => ({
        ...prevData,
        totalPrice: totalPrice,
        inclusiveTax: (totalPrice * 1.13).toFixed(2),
        taxAmount: (totalPrice * 1.13 - totalPrice).toFixed(2),
      }));
    }
  };
  const applyCoupon = (type, discount, coupon) => {
    getTotalPrice();
    setCart((prevData) => {
      let totalAmount = cart.inclusiveTax;
      if (type === "fixed") {
        totalAmount -= discount;
      } else if (type === "percentage") {
        const discountAmount = (totalAmount * discount) / 100;
        totalAmount -= discountAmount;
      }
      // Return the updated amount
      return {
        ...prevData,
        inclusiveTax: totalAmount.toFixed(2),
        appliedCoupon: coupon,
      };
    });
  };
  const removeCoupon = () => {
    getTotalPrice();
    setCart((prevData) => ({ ...prevData, appliedCoupon: null }));
  };

  useEffect(() => {
    getTotalPrice();
  }, [cart.item]);

  return (
    <CartContext.Provider
      value={{
        ...cart,
        addItemToCart,
        removeItemFromCart,
        clearCart,
        getTotalItems,
        getTotalPrice,
        applyCoupon,
        removeCoupon,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to access the cart context
export const useCartContext = () => {
  return useContext(CartContext);
};
