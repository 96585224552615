import React from "react";
import { Link } from "react-router-dom";
import { BsHouse } from "react-icons/bs";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import Coupon from "../components/Coupon";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Loading from "../components/Loading";
import { useAdminContext } from "../contexts/AdminContext";
const Coupons = () => {
  const { currentColor, currentMode } = useStateContext();
  const { rooms } = useFetchContext();
  const { coupons } = useAdminContext();
  const [text, setText] = React.useState("");
  const [filteredCoupons, setFilteredCoupons] = React.useState([]);
  React.useEffect(() => {
    if (coupons !== null) {
      setFilteredCoupons(
        coupons.filter(
          ({ code, type, discount }) =>
            code.toLowerCase().includes(text.toLowerCase()) ||
            type.toLowerCase().includes(text.toLowerCase()) ||
            discount.toString().includes(text.toLowerCase())
        )
      );
    }
  }, [text, coupons]);

  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font tracking-widest">
            Coupons
          </h1>
          <div className="flex items-center justify-center">
            <input
              type="text"
              name="name"
              className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
              placeholder="Search Coupon..."
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
            />
          </div>
        </div>
        {coupons === null ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading
              color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
              bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
            />
          </div>
        ) : (
          <div className="flex flex-wrap -m-2">
            {filteredCoupons?.map(
              ({ code, _id, type, isValid, discount, countUsed }) => (
                <Coupon
                  key={_id}
                  id={_id}
                  isValid={isValid}
                  couponCode={code}
                  type={type}
                  discount={discount}
                  countUsed={countUsed}
                />
              )
            )}
            <Link
              to={"/admin/create-coupon"}
              style={{ color: currentColor }}
              className="p-2 w-full"
            >
              <div className="border gap-4 py-3 cursor-pointer hover:ring-2 dark:hover:ring-white hover:ring-black dark:border-white-shade border-black-shade rounded-md flex items-center justify-center ">
                <AiOutlinePlusCircle className="w-7 h-7" />
                <p className=" ">Add Coupon</p>
              </div>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Coupons;
