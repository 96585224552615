import React, { useRef, useState } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import logo from "../data/logo.png";
import Loading from "../components/Loading";
import { TbShoppingCartCheck, TbShoppingCartFilled } from "react-icons/tb";
import { IoTicketOutline } from "react-icons/io5";
import { useFetchContext } from "../contexts/FetchContext";
import { MdEdit } from "react-icons/md";
import CustomModal from "../components/CustomModal";
import { usePostContext } from "../contexts/PostContext";

const Profile = () => {
  const { user, logout } = useAuthContext();
  const { userAppointments } = useFetchContext();
  const { AddAvatar, UpdateAvatar } = usePostContext();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const handleLogout = async () => {
    await logout();
    navigate("/");
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleImageUpdate = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    data?.avatar && user?.avatar?.url
      ? await UpdateAvatar(data)
      : await AddAvatar(data);
    setIsLoading(false);
    handleClose();
  };
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (e.target.files.length > 0) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setData({ avatar: reader.result.toString() });
      };
      reader.readAsDataURL(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };
  const inputRef = useRef();
  return (
    <div className="container px-5 py-24 mx-auto">
      {!user ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading />
        </div>
      ) : (
        <>
          <div className="bg-black-shade text-body-heading rounded-xl p-4 shadow-xl">
            <div className="flex flex-col justify-center items-center">
              <div className="relative w-32 h-32 rounded-full  flex items-center justify-center bg-black-shade border border-body-heading mt-2">
                <div className="relative w-32 h-32 rounded-full overflow-hidden flex items-center justify-center bg-black-shade border border-body-heading ">
                  <img
                    src={user?.avatar?.url || logo}
                    className="object-cover h-full w-full"
                  />
                </div>
                <div className="absolute z-10 bottom-0 right-0">
                  <button
                    onClick={handleOpen}
                    className="w-8 h-8 text-white-shade bg-body-heading rounded-full flex items-center justify-center"
                  >
                    <MdEdit />
                  </button>
                </div>
              </div>
              <p className="font-semibold text-2xl mt-1">{user.name}</p>
              <p className=" text-base ">{user.email}</p>
              <div className="flex flex-col md:flex-row w-full gap-2 md:gap-4">
                <Link
                  to={"settings"}
                  className="inline-flex text-white-shade transition duration-300 items-center justify-center bg-body-heading p-2 md:p-4 rounded-lg shadow-xl w-full bg-cover bg-center h-12 md:h-20 cursor-pointer hover:bg-white-shade hover:text-black-shade mt-2"
                >
                  <h3 className="text-center text-sm md:text-2xl">
                    Profile Settings
                  </h3>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5 ml-1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
                <button
                  onClick={handleLogout}
                  className=" text-white-shade transition duration-300 bg-body-heading p-2 md:p-4 rounded-lg shadow-xl w-full flex items-center justify-center bg-cover bg-center h-12 md:h-20 cursor-pointer hover:bg-white-shade hover:text-black-shade mt-2"
                >
                  <h3 className="text-center text-sm md:text-2xl">Logout</h3>
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <Link
              className="relative w-full cursor-pointer text-white-shade hover:scale-105 hover:bg-white-shade hover:text-black-shade h-52 bg-cover bg-black-shade bg-center group rounded-lg overflow-hidden mt-4 shadow-lg transition duration-300 ease-in-out"
              style={{
                backgroundImage: `url(${logo})`,
              }}
              to={"active-services"}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 transition duration-300 ease-in-out"></div>
              <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                <div>
                  <div className=" text-lg flex space-x-2 items-center">
                    <TbShoppingCartCheck className="h-5 w-5" />
                    <p className="font-bold">Active Services</p>
                  </div>
                  <h3 className=" text-3xl mt-2 font-bold">
                    {user.userServices.length}
                  </h3>
                </div>
              </div>
            </Link>
            <Link
              className="relative w-full cursor-pointer text-white-shade hover:scale-105 hover:bg-white-shade hover:text-black-shade h-52 bg-cover bg-black-shade bg-center group rounded-lg overflow-hidden mt-4 shadow-lg transition duration-300 ease-in-out"
              style={{
                backgroundImage: `url(${logo})`,
              }}
              to={"total-orders"}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 transition duration-300 ease-in-out"></div>
              <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                <div>
                  <div className=" text-lg flex space-x-2 items-center">
                    <TbShoppingCartFilled className="h-5 w-5" />
                    <p className="font-bold">Total Orders</p>
                  </div>
                  <h3 className=" text-3xl mt-2 font-bold">
                    {userAppointments?.length}
                  </h3>
                </div>
              </div>
            </Link>
            <Link
              className="relative w-full cursor-pointer text-white-shade hover:scale-105 hover:bg-white-shade hover:text-black-shade h-52 bg-cover bg-black-shade bg-center group rounded-lg overflow-hidden mt-4 shadow-lg transition duration-300 ease-in-out"
              style={{
                backgroundImage: `url(${logo})`,
              }}
              to={"discount-coupons"}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 transition duration-300 ease-in-out"></div>
              <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                <div>
                  <div className=" text-lg flex space-x-2 items-center">
                    <IoTicketOutline className="h-5 w-5" />
                    <p className="font-bold">Discount Coupons</p>
                  </div>
                  <h3 className=" text-3xl mt-2 font-bold">
                    {user?.discountCoupons?.length}
                  </h3>
                </div>
              </div>
            </Link>
            <div
              className="relative w-full cursor-pointer text-white-shade hover:scale-105 hover:bg-white-shade hover:text-black-shade h-52 bg-cover bg-black-shade bg-center group rounded-lg overflow-hidden mt-4 shadow-lg transition duration-300 ease-in-out"
              style={{
                backgroundImage: `url(${logo})`,
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 transition duration-300 ease-in-out"></div>
              <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                <div>
                  <div className=" text-lg flex space-x-2 items-center">
                    <span className="bg-red-500 rounded-md p-2 flex items-center"></span>
                    <p className="font-bold">Last Order</p>
                  </div>
                  <h3 className=" text-3xl mt-2 font-bold">--/--/----</h3>
                </div>
              </div>
            </div>
          </div>
          <CustomModal open={open} handleClose={handleClose}>
            <form onSubmit={handleImageUpdate} className="w-full max-w-2xl p-4">
              <div className="text-xl flex w-full items-center mt-2 justify-center text-black-shade ">
                Change Picture
              </div>
              <div className="w-full items-center justify-center flex">
                <div className=" w-32 h-32 rounded-full flex items-center justify-center bg-black-shade border overflow-hidden border-body-heading mt-2">
                  <img
                    src={imagePreview || logo}
                    className="object-cover h-full w-full"
                  />
                </div>
              </div>
              <div className="w-full flex items-center justify-center my-6">
                <input
                  className="w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 hidden"
                  aria-describedby="file_input_help"
                  ref={inputRef}
                  onChange={handleProfileImageChange}
                  type="file"
                />
                <button
                  type="button"
                  onClick={() => inputRef.current.click()}
                  className="text-white-shade disabled:bg-body-heading-hover font-semibold bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base "
                >
                  Upload Image
                </button>
              </div>

              <div className="w-full flex text-sm justify-end mb-3">
                <button
                  type="button"
                  disabled={isLoading ? true : false}
                  onClick={handleClose}
                  className="text-white-shade mr-3 disabled:bg-body-heading-hover font-semibold bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base "
                >
                  Cancel
                </button>
                <button
                  disabled={isLoading ? true : false}
                  type="submit"
                  className="text-white-shade disabled:bg-body-heading-hover font-semibold bg-btn-color border-0 py-2 px-3 focus:outline-none hover:bg-body-heading-hover text-nowrap rounded text-sm md:text-base "
                >
                  {isLoading ? "Updating..." : "Submit"}
                </button>
              </div>
            </form>
          </CustomModal>
        </>
      )}
    </div>
  );
};

export default Profile;
