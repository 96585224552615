import React from "react";
import { GoClock } from "react-icons/go";

const ShopTimeSlot = ({ time, onClick, value }) => {
  return (
    <div
      onClick={onClick}
      className={`p-1 sm:p-2 cursor-pointer duration-300 transition ${
        value === time
          ? " text-black-shade bg-white-shade   border-black-shade"
          : "text-white-shade bg-black-shade border-black-shade dark:text-white-shade dark:bg-secondary-dark-bg dark:border-white-shade"
      } rounded-sm hover:bg-white-shade hover:border-black-shade hover:text-black-shade dark:hover:bg-white-shade dark:hover:border-black-shade dark:hover:text-black-shade items-center flex border`}
    >
      <span className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2 inline-flex items-center justify-center rounded-full flex-shrink-0">
        <GoClock />
      </span>
      <p className="text-xs md:text-sm lg:text-base">{time}</p>
    </div>
  );
};

export default ShopTimeSlot;
