import React, { useRef, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../../contexts/ContextProvider";
import logo from "../../../data/photo-3.jpg";
import { FaMinusCircle } from "react-icons/fa";
import { useCartContext } from "../../../contexts/CartContext";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import CartItem from "./CartItem";
import { usePostContext } from "../../../contexts/PostContext";

const Cart = () => {
  const { VerifyCoupon, errorMessage } = usePostContext();
  const { setIsClicked, initialState } = useStateContext();
  const {
    item,
    removeItemFromCart,
    totalPrice,
    clearCart,
    inclusiveTax,
    taxAmount,
    applyCoupon,
    appliedCoupon,
    removeCoupon,
  } = useCartContext();
  const { isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  const inputRef = useRef();

  const handleApplyCoupon = async () => {
    let data = {
      code: inputRef.current.value,
    };
    let couponData = await VerifyCoupon(data);
    if (couponData._id) {
      applyCoupon(couponData.type, couponData.discount, couponData);
    }
  };
  return (
    <div className="bg-half-transparent w-full fixed nav-item top-0 right-0 bottom-0 overflow-y-auto">
      <div className="float-right flex flex-col h-screen duration-1000 text-white-shade transition bg-black-shade w-80 md:w-400 p-4 md:p-8">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg">My Cart</p>
          <button
            onClick={() => setIsClicked(initialState)}
            className="cursor-pointer rounded-full hover:text-black p-2 hover:bg-white-shade"
          >
            <MdOutlineCancel className=" h-5 w-5" />
          </button>
        </div>
        {item.length > 0 && (
          <button
            onClick={() => clearCart()}
            className="flex justify-end my-2 px-2 cursor-pointer w-full underline text-sm"
          >
            Clear Cart
          </button>
        )}
        <div className="flex-grow overflow-x-hidden overflow-y-auto">
          <div className="h-full ">
            {item.length > 0 ? (
              item.map(({ appointmentDetails, productDetails, id }, i) => (
                <CartItem
                  key={i}
                  productDetails={productDetails}
                  name={productDetails.name}
                  serviceId={appointmentDetails.serviceId}
                  onClick={() => removeItemFromCart(id)}
                  image={productDetails.images[0].url}
                  date={appointmentDetails.date}
                  startTime={appointmentDetails.startTime}
                  endTime={appointmentDetails.endTime}
                  price={appointmentDetails.price}
                />
              ))
            ) : (
              <div className="flex flex-col items-center w-full h-full  justify-center py-10">
                <div className="">Cart is Empty</div>
                <button
                  onClick={() => {
                    setIsClicked(initialState);
                    navigate("/");
                  }}
                  className="border duration-300 hover:bg-white-shade hover:text-black-shade hover:border-black-shade text-sm mt-2 border-white-shade rounded-md p-2"
                >
                  Add Products
                </button>
              </div>
            )}
            {item.length > 0 && (
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={() => {
                    setIsClicked(initialState);
                    navigate("/");
                  }}
                  className="border duration-300 hover:bg-white-shade hover:text-black-shade hover:border-black-shade text-sm mt-2 border-white-shade rounded-md p-2"
                >
                  Add More Products
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 mb-3">
          {isLoggedIn &&
            item.length > 0 &&
            (appliedCoupon === null ? (
              <>
                <div className="flex items-center">
                  <input
                    ref={inputRef}
                    type="text"
                    id="applyCode"
                    className="bg-transparent border border-white-shade text-white-shade rounded-2xl w-full p-2 text-xs"
                    placeholder="Coupon Code"
                  />
                  <button
                    onClick={handleApplyCoupon}
                    className="transition duration-300 hover:text-black-shade hover:bg-white-shade text-white-shade text-xs border border-white-shade ml-2 px-3 py-2 rounded-2xl"
                  >
                    Apply
                  </button>
                </div>
              </>
            ) : (
              <div className="flex items-center">
                <div className="py-2 w-full text-xs text-center border-white-shade border rounded-2xl">
                  {appliedCoupon.code}
                </div>
                <button
                  onClick={removeCoupon}
                  className="transition duration-300 hover:text-black-shade hover:bg-white-shade text-white-shade text-xs border border-white-shade ml-2 px-3 py-2 rounded-2xl"
                >
                  Remove
                </button>
              </div>
            ))}
          <div className="flex justify-between mt-2 text-sm items-center">
            <p className="">Sub Total</p>
            <p className="font-semibold">
              {item.length == 0 ? "--" : `${totalPrice}$`}
            </p>
          </div>
          <div className="flex justify-between mt-2 text-sm items-center">
            <p className="">HST</p>
            <p className="font-semibold">
              {item.length == 0 ? "--" : `${taxAmount}$`}
            </p>
          </div>
          {appliedCoupon && (
            <div className="flex justify-between mt-2 text-sm items-center">
              <p className="">Coupon Discount</p>
              <p className="font-semibold">
                {appliedCoupon.discount}
                {appliedCoupon.type === "fixed" ? "$" : "%"}
              </p>
            </div>
          )}
          <div className="flex justify-between items-center mt-2 text-sm">
            <p className="">Total</p>
            <p className="font-semibold">
              {item.length == 0 ? "--" : `${inclusiveTax}$`}
            </p>
          </div>
        </div>
        <div className="mt-2">
          <button
            onClick={() => {
              if (isLoggedIn) {
                navigate("/checkout");
                setIsClicked(initialState);
              } else {
                navigate("/login");
              }
            }}
            className="w-full items-center transition-all duration-300 bg-btn-color border-0 py-3 text-white-shade px-3 focus:outline-none hover:bg-body-heading-hover rounded text-lg font-bold mt-4 md:mt-0"
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
